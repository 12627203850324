import styled from 'styled-components';
import { CSS_GUIDE } from 'components/styled/constants';
// from model https://codepen.io/nikkk-me/pen/abvPjeG
// TODO FIRST: fix the color of the moving pill -> seems that the white applies to what is not moving
// todo if needed: adapt props to choose between fluid and max width
const leftWidth = 240;
const fluidWith = 100;

export const SwitchButton = styled.div`
  background: rgba(${CSS_GUIDE.colors.midGrey2.rgb}, 0.2); // main bg
  border-radius: 50px;
  overflow: hidden;
  //width: ${leftWidth}px;
  width: ${fluidWith}%;
  text-align: center;
  font-size: 14px;
  letter-spacing: 1px;
  color: #ffffff;
  position: relative;
  //padding-right: ${leftWidth / 2}px;
  padding-right: ${fluidWith / 2}%;

  // this is the second part of the switch, the right part
  &:before {
    content: '${(props) => props.alterText}';
    position: absolute;
    //color: #ffffff;
    top: 0;
    bottom: 0;
    right: 0;
    //width: ${leftWidth / 2}px;
    width: ${fluidWith / 2}%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    pointer-events: none;
  }
`;

export const SwitchButtonCheckbox = styled.input`
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;
`;

export const SwitchButtonLabel = styled.label`
  color: #ffffff;
  ${SwitchButtonCheckbox}:checked + &:before {
    transform: translateX(${fluidWith}%);
    // transform: translateX(${leftWidth / 2}px);
    transition: transform 200ms linear;
  }

  ${SwitchButtonCheckbox} + & {
    position: relative;
    padding: 15px 0;
    display: block;
    user-select: none;
    pointer-events: none;

    &:before {
      content: '';
      background: ${CSS_GUIDE.colors.primary.hex};
      height: 100%;
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      border-radius: 50px;
      transform: translateX(0);
      transition: transform 200ms;
    }
  }

  span {
    position: relative;
  }
`;
