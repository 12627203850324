import React from 'react';
import SwitchCheckbox from 'components/forms/SwitchCheckbox';
import Card from 'components/styled/wrappers/Card';
import userIcon from './user.svg';
import RadioButtonComponent from 'components/forms/RadioButtonComponent';

const ProxiedParticipants = ({
  options = [],
  users = [],
  votes,
  setVotes,
  itemId,
  sameForOthers,
  setSameForOthers
}) => {
  const applySameVoteForAll = () => {
    setSameForOthers(!sameForOthers);
    if (sameForOthers) {
      const initVote = masterVote(votes, sameForOthers, users, itemId);
      setVotes([votes.find((v) => v.master), ...initVote]);
    }
  };

  return (
    <div className='boxShadow mt-3 mb-3 p-3'>
      <h1 className='mb-3'>Mes procurations</h1>
      <SwitchCheckbox
        text='Appliquer mon vote aux mandants'
        toggled={sameForOthers}
        id={'mandants'}
        onChange={applySameVoteForAll}
      />
      {!sameForOthers && (
        <div className='row'>
          {users.map((user) => (
            <Voter
              key={user.id}
              user={user}
              options={options}
              itemId={itemId}
              votes={votes}
              setVotes={setVotes}
              sameForOthers={sameForOthers}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default ProxiedParticipants;

const Voter = ({ options, user, itemId, votes, setVotes, sameForOthers }) => {
  const [myVote, setMyVote] = React.useState(votes.find((v) => v.participantId === user.id)?.initVote ?? null);

  React.useEffect(() => {
    if (sameForOthers) {
      setMyVote(votes.find((v) => v.master)?.initVote);
    } else {
      setMyVote(votes.find((v) => v.participantId === user.id)?.initVote);
    }
  }, [sameForOthers]);

  const handleChange = (event) => {
    setMyVote(parseInt(event.target.value));
    const localVote = votes?.find((v) => v.participantId === user?.id);

    setVotes([
      ...votes.filter((vote) => vote.participantId !== user.id),
      {
        ...localVote,
        initVote: parseInt(event.target.value)
      }
    ]);
  };

  return (
    <div className='col-md-6 col-xl-4'>
      <Card>
        <img src={userIcon} alt='' className='mx-auto d-block' />
        <p className='vote-mandant-name text-center'>{getName(user)}</p>
        <ul className='vote-mandant-choices'>
          {options &&
            options.map((option) => (
              <li key={`Li-${itemId}-key-${option.id}`}>
                <RadioButtonComponent
                  onChange={handleChange}
                  key={`${itemId}-key-${option.id}-slave`}
                  value={option.id}
                  labelText={option.label || option.supplier.company.name}
                  name={`${itemId}voteSystem${user.id}-mandant`}
                  classes='mt-2 mb-2 col-xs-3 col-sm-3'
                  checked={option.id === myVote}
                />
              </li>
            ))}
        </ul>
      </Card>
    </div>
  );
};

function masterVote(votes, sameForOthers, mandants, itemId) {
  const initVote = [];
  if (mandants?.length > 0) {
    for (let mandant of mandants) {
      initVote.push({
        master: false,
        item: `/api/v1/meeting_items/${itemId}`,
        participant: `/api/v1/meeting_event_participants/${mandant.id}`,
        participantId: mandant.id,
        initVote: votes.filter((vote) => vote.master)[0]?.initVote
      });
    }
  }
  return initVote;
}

function getName(user) {
  const data = user.owner;
  return data?.company?.name ?? data?.person?.firstname + ' ' + data?.person?.lastname;
}
