import styled from 'styled-components';
import breakpoint from '../breakpoints/breakpoints';

// todo: verify that the flex rule and the height don't break anything on other device
const Main = styled.main.attrs({
  className: 'ag-app-main'
})`
  flex: 1;
  height: 100%;
  padding-bottom: 30px;

  @media only screen and ${breakpoint.device.lg} {
    overflow-y: auto;
    padding-left: calc(60px + 0.5rem); // the extra padding will be substract by bootstrap's row negative margin
    padding-bottom: 50px;
  }
`;

export default Main;
Main.displayName = 'Main';
