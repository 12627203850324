import React from 'react';
import ResidenceBlock from 'pages/dashboard/components/residence/ResidenceBlock';
import ResidencesGroupWrapper from 'components/styled/wrappers/ResidencesGroupWrapper';
import { Loader } from 'components/loader';

const ResidencesGroup = ({ isLoading, residences, error, isDashboard }) => {
  return (
    <ResidencesGroupWrapper>
      {isLoading && <Loader />}
      {error && 'Data unavailable'}
      <ul className='residences-list block-section__custom-container block-section-stretcher'>
        {residences &&
          residences.map((residence) => {
            return (
              <li key={residence.id} className='residences-list__item'>
                <ResidenceBlock residence={residence} isDashboard={isDashboard} />
              </li>
            );
          })}
      </ul>
    </ResidencesGroupWrapper>
  );
};

export default ResidencesGroup;
