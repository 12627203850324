import React from 'react';
import CircleNumber from 'components/styled/atoms/CircleNumber';
import styled from 'styled-components';
import breakpoint from 'components/styled/breakpoints/breakpoints';
import { HeadlineStyled3 } from 'components/styled/headings/HeadlineStyled';
import AppIcon from 'components/icons/AppIcon';

const MeetingItemBlock = ({
  step,
  children,
  mainTitle = 'Ordre du jour',
  secondTitle = null,
  showBottom,
  showMobile,
  iconName,
  otherClass = '',
  hideCircle = false
}) => {
  return (
    <MeetingItemBlockStyled className={`meeting-item-block ${otherClass}`} showMobile={showMobile}>
      <div className={'meeting-item-block__half meeting-item-block__top'}>
        <header className={'meeting-item-block__top__header'}>
          {!hideCircle && (
            <CircleNumber small className={'meeting-item-block__step-number'}>
              {iconName ? <AppIcon name={iconName} /> : step}
            </CircleNumber>
          )}
          <HeadlineStyled3 $weight={'bold'}>{mainTitle}</HeadlineStyled3>
        </header>
      </div>

      {showBottom && (
        <div className={'meeting-item-block__half meeting-item-block__bottom'}>
          {secondTitle && <HeadlineStyled3 $weight={'bold'}>{secondTitle}</HeadlineStyled3>}
          {children}
        </div>
      )}
    </MeetingItemBlockStyled>
  );
};

export default MeetingItemBlock;

const MeetingItemBlockStyled = styled.article`
  display: ${(props) => (props.showMobile ? 'block' : 'none')};

  position: relative;
  top: 20px;

  .meeting-item-block__half {
    padding: 15px;
  }

  .meeting-item-block__top {
    background: #f3f8fa; // #eef3f4;
    border-radius: ${(props) => (!props.showBottom ? '15px' : '15px 15px 0 0')};
  }

  .meeting-item-block__bottom {
    background: #ffffff;
    border-radius: 0 0 15px 15px;

    h3 {
      margin: 30px 0;
    }
  }

  .meeting-item-block__step-number {
    margin-right: 20px;
  }

  .meeting-item-block__top__header {
    margin-bottom: 30px;
    display: flex;
    align-items: center;

    .headline-3 {
      margin-bottom: 0;
    }
  }

  .circle-number {
    .aea-svg {
      fill: currentColor;
      width: 20px;
      height: 20px;
    }
  }

  .meeting-item-block__subtitle {
    font-size: 1rem;
  }

  @media only screen and ${breakpoint.device.lg} {
    top: 0;
    display: block;
    width: 100%;
    border-radius: 15px 15px 0 0;

    .meeting-item-block__half {
      padding: 35px 70px;
    }
  }
`;
