import React from 'react';
import HeaderWrapper from '../styled/wrappers/HeaderWrapper';
import MainSubContainer from 'components/styled/wrappers/MainSubContainer';
import { useAuthContext } from 'auth/ProvideAuth';
import { Button } from 'components/styled/buttons/ButtonStyled';
import AppIcon from 'components/icons/AppIcon';

const Header = () => {
  const { signOut } = useAuthContext();
  const logOut = () => signOut();

  return (
    <HeaderWrapper className=''>
      <MainSubContainer>
        <div className='user-menu'>
          <Button className='d-none d-lg-flex' onClick={logOut}>
            Logout
          </Button>
        </div>
      </MainSubContainer>
    </HeaderWrapper>
  );
};

export default Header;
