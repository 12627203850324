import styled from 'styled-components';
import Card from 'components/styled/wrappers/Card';
import { CSS_GUIDE } from 'components/styled/constants';
import breakpoint from 'components/styled/breakpoints/breakpoints';

const MeetingItemStyled = styled(Card).attrs({
  className: 'meeting-item-styled'
})`
  position: relative;
  padding: 25px 15px 25px 50px;

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .meeting-status {
    font-size: 0.875rem;
    font-weight: 700;
    color: ${({ isOngoingStatus }) => (isOngoingStatus ? CSS_GUIDE.colors.success : CSS_GUIDE.colors.primary.hex)};
  }

  h4 {
    color: ${CSS_GUIDE.colors.primary.hex};
  }

  .meeting-pill {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  &:before {
    content: '';
    display: block;
    width: 4px;
    height: 80%;
    top: 10%;
    left: 20px;
    border-radius: 50px;
    background-color: ${({ isOngoingStatus }) =>
      isOngoingStatus ? CSS_GUIDE.colors.success : CSS_GUIDE.colors.secondary.hex};
    position: absolute;
  }

  @media only screen and ${breakpoint.device.lg} {
    max-width: 430px;

    &:before {
      left: 0;
      height: 100%;
      top: 0;
      border-radius: ${CSS_GUIDE.borderRadius} 0 0 ${CSS_GUIDE.borderRadius};
      width: 9px;
    }

    &.meeting-styled {
      border-radius: 10px ${CSS_GUIDE.borderRadius} ${CSS_GUIDE.borderRadius} 10px;
    }

    padding: 20px 20px 20px 50px;
    margin-left: 0;
    margin-right: 0;

    & + & {
      margin-left: 30px;
    }

    button {
      display: inline-flex;
      width: auto;
      padding: 0 60px;
    }
  }
`;

export default MeetingItemStyled;
MeetingItemStyled.displayName = 'MeetingItemStyled';
