import React from 'react';
import { HeadlineStyled1, HeadlineStyled3 } from 'components/styled/headings/HeadlineStyled';
import styled from 'styled-components';
import CustomLinkToStyled from 'components/styled/buttons/CustomLinkToStyled';
import AppIcon from 'components/icons/AppIcon';
import { useHistory, useParams } from 'react-router-dom';
import breakpoint from 'components/styled/breakpoints/breakpoints';

const PageTitle = ({ meeting, otherText, hasBackTo, subText, mainText = 'Assemblée Générale', classes }) => {
  const history = useHistory();
  const { id } = useParams();

  return (
    <PageTitleStyled className={'common-page-header--styled d-flex'}>
      {hasBackTo && (
        <AppIcon name={'arrow'} onClick={() => history.push(`/meetings/${id}/items`)} classes={'back-to-btn'} />
      )}
      <div className={'page-title-content'}>
        <HeadlineStyled1 className='mb-0' $weight='medium'>
          {mainText} <br />
          {meeting !== undefined && 'Copropriété ' + meeting?.jointOwnership?.name}
        </HeadlineStyled1>

        {!!subText && (
          <div className='d-flex page-title-subtext mb-1 mt-3'>
            <HeadlineStyled3 $weight='bold' className='mb-0'>
              {subText}
              {!!otherText && <span> - {otherText}</span>}
            </HeadlineStyled3>
          </div>
        )}
      </div>
    </PageTitleStyled>
  );
};

const PageTitleStyled = styled.header`
  margin-bottom: 50px;

  flex-direction: column-reverse;
  align-items: flex-end;

  .back-to-btn {
    transform: rotate(90deg);
    position: relative;
    top: 5px;
    cursor: pointer;
  }

  .headline-1 {
    margin-bottom: 40px;
    text-align: right;
    font-size: 1.25rem;
  }

  .page-title-subtext {
    justify-content: flex-end;
  }

  justify-content: flex-end;

  @media only screen and ${breakpoint.device.lg} {
    justify-content: flex-start;
    flex-direction: row;
    align-items: initial;

    .back-to-btn {
      margin-right: 30px;
    }

    .headline-1 {
      text-align: left;
      font-size: 2.125rem;
      padding: 0;
    }

    .page-title-subtext {
      justify-content: flex-start;
    }
  }
`;

export default PageTitle;
