import React from 'react';
import styled from 'styled-components';
import AppIcon from 'components/icons/AppIcon';
import Card from 'components/styled/wrappers/Card';
import { CSS_GUIDE } from 'components/styled/constants';
import breakpoint from 'components/styled/breakpoints/breakpoints';
import { Link } from 'react-router-dom';

const Folder = ({ className, doc, jointOwnershipId /*, filesCount*/ }) => {
  return (
    <div className={`folder-block ${className}`}>
      <Link to={`/residences/${jointOwnershipId}/${doc.type}`}>
        <Card>
          <AppIcon name='folder' />
          <p className='folder-name'>{doc.title}</p>

          {/*<p className='folder-block__files-count'>
        <span>{filesCount}</span> fichiers
      </p>*/}
        </Card>
      </Link>
    </div>
  );
};

const FolderStyled = styled(Folder).attrs({
  className: 'folder-block-styled'
})`
  color: ${CSS_GUIDE.colors.primary.hex};

  .styled-card {
    padding-top: 20px;
    min-height: 160px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0;
  }

  #svg-folder {
    max-width: 40px;
    max-height: 40px;
    margin-bottom: 10px;
  }

  .folder-name {
    font-weight: 700;
    text-align: center;
  }

  @media only screen and ${breakpoint.device.md} {
    width: 170px;
  }
`;

export default FolderStyled;
FolderStyled.displayName = 'FolderStyled';
