import React from 'react';
import MeetingItemBlock from './MeetingItemBlock';
import { useParams } from 'react-router-dom';
import { useGetMeetingItemByIdQuery } from 'api/services/meetingApi';
import MeetingPageWrapper from '../MeetingPageWrapper';
import DocumentDisplay from 'components/commun/documentDisplay';
import { HeadlineStyled3 } from 'components/styled/headings/HeadlineStyled';
import { Loader } from 'components/loader';
import MeetingItemComment from 'pages/meetings/components/MeetingItemComment';

const MeetingItemDetail = () => {
  const { itemId } = useParams();
  const { data: item, isLoading } = useGetMeetingItemByIdQuery(itemId);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <MeetingPageWrapper hasBackTo subText={'Ordre du jour'}>
      {(meeting) => (
        <div>
          <MeetingItemBlock
            mainTitle={item?.translations[meeting?.mainLanguage].title}
            step={item?.position - 1}
            showMobile
            showBottom={item?.documents?.length > 0 || item?.options?.length > 0}
          >
            <div className='container'>
              <div className='row'>
                <HeadlineStyled3 $weight={'bold'}>{'Pièces jointes'}</HeadlineStyled3>
                {item?.documents?.map((doc) => (
                  <div key={doc.id} className={'col-lg-6 mb-4'}>
                    <DocumentDisplay id={doc.id} name={doc.name} />
                  </div>
                ))}
              </div>

              {item.options?.length > 0 && (
                <div className={'row'}>
                  <HeadlineStyled3 $weight={'bold'}>{'Prestataires proposés'}</HeadlineStyled3>
                  {item.options.map((option) => {
                    return (
                      <div key={option.id} className={'col-lg-6'}>
                        <h4 className={'meeting-item-block__subtitle'}>{option.supplier.company.name}</h4>
                        <div className={'row'}>
                          {option?.documents?.length > 0 &&
                            option.documents.map((doc) => (
                              <div key={doc.id} className={'col-lg-6 mb-4'}>
                                <DocumentDisplay id={doc.id} name={doc.name} price={option.price} />
                              </div>
                            ))}
                        </div>
                      </div>
                    );
                  })}
                  <div className={'col-lg-6 mb-4'}>
                    {item?.hasOwnProperty('compareTable') && (
                      <DocumentDisplay id={item.compareTable.id} name={item.compareTable.name} altDisplay />
                    )}
                  </div>
                </div>
              )}
            </div>
          </MeetingItemBlock>
          <MeetingItemComment item={item} participantId={meeting?.event?.currentParticipant?.id} />
        </div>
      )}
    </MeetingPageWrapper>
  );
};

export default MeetingItemDetail;
