import React from 'react';
import { HeadlineStyled3 } from 'components/styled/headings/HeadlineStyled';
import MeetingInfoBlock from 'pages/meetings/components/MeetingInfoBlock';
import Card from 'components/styled/wrappers/Card';
import MeetingActionBlock from 'pages/meetings/components/MeetingActionBlock';
import styled from 'styled-components';
import ParticipationForm from 'pages/dashboard/components/meetingItems/ParticipationForm';
import MeetingPageWrapper from 'pages/meetings/MeetingPageWrapper';
import { dateFormatter } from 'utils/date';

const MeetingDetail = () => {
  return (
    <MeetingPageWrapper>
      {(meetingData) => (
        <MeetingDetailStyled>
          <main className={'meeting-detail__main'}>
            <div className='row'>
              <div className={'col-lg-6'}>
                <MeetingInfoBlock
                  infoType={'date'}
                  value={`${dateFormatter(meetingData?.date, 'full')} à ${dateFormatter(meetingData?.date, 'hour')}`}
                />
              </div>
              <div className={'col-lg-6'}>
                <MeetingInfoBlock infoType={'location'} value={meetingData?.location} />
              </div>
            </div>

            <div className='row'>
              <div className='col-xl-8'>
                <MeetingActionBlock
                  title={'Ordre du jour'}
                  linkTo={`/meetings/${meetingData?.id}/items`}
                  text={
                    'Prenez connaissance dès maintenant de l’ordre du jour, adressez un commentaire à destination du syndic ou suggérez une nouvelle résolution.'
                  }
                />

                <MeetingActionBlock
                  title={'Vote par correspondance'}
                  iconName={'vote-box'}
                  linkTo={`/meetings/${meetingData?.id}/vote`}
                  text={
                    'Nous vous invitons à voter sur les différents points de l’ordre du jour. Après votre vote, veuillez bien confirmer et signer, afin que vos votes soient bien pris en considération.'
                  }
                />
              </div>
              <div className='col-xl-4'>
                <Card className={'participation-card'}>
                  <HeadlineStyled3 $weight={'bold'}>Serez-vous présent?</HeadlineStyled3>
                  <ParticipationForm meeting={meetingData} />
                </Card>
              </div>
            </div>
          </main>
        </MeetingDetailStyled>
      )}
    </MeetingPageWrapper>
  );
};

export default MeetingDetail;

const MeetingDetailStyled = styled.div`
  .meeting-detail__header {
    text-align: center;
    margin-bottom: 30px;
  }

  .participation-card {
    .headline-3 {
      margin-bottom: 40px;
    }
  }
`;
