import React, { createContext, useContext } from 'react';
import useProvideAuth from 'auth/useProvideAuth';

const AuthContext = createContext();

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuthContext().

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export function useAuthContext() {
  return useContext(AuthContext);
}
