import React from 'react';
import MeetingItemComponent from './MeetingItemComponent';
import MeetingItemsWrapper from 'components/styled/wrappers/MeetingItemsWrapper';
import { isAfter, isSameDay } from 'date-fns';

const MeetingItems = ({ meetings }) => {
  return (
    <MeetingItemsWrapper>
      <h3 className='block-section__title'>Vos prochaines assemblées</h3>

      {meetings?.length > 0 && (
        <ul className='meetings-list'>
          {meetings
            .filter(
              (meeting) =>
                meeting.status_id === 3 &&
                (isAfter(new Date(meeting?.date), new Date()) || isSameDay(new Date(meeting?.date), new Date()))
            )
            .map((meeting) => {
              return (
                <li key={meeting.id} className='meetings-list__item'>
                  <MeetingItemComponent meeting={meeting} />
                </li>
              );
            })}
        </ul>
      )}

      {meetings?.length === 0 && "Vous n'avez pas d'assemblée générale à venir"}
    </MeetingItemsWrapper>
  );
};

export default MeetingItems;
