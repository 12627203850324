import React from 'react';
import { useSigneForEventMutation } from 'api/services/meetingEventParticipantApi';
import SwitchWithText from 'components/forms/SwitchWithText';
import SignatureComponent from '../components/signature/SignatureComponent';
import TextSignature from '../components/signature/TextSignature';
import { toast } from 'react-toastify';

function SignatureType({ event, title, setStep }) {
  const [sigChoice, setSigChoice] = React.useState(false);
  const [signature, setSignature] = React.useState(null);
  const [signeForEvent, { isSuccess }] = useSigneForEventMutation();

  React.useEffect(() => {
    if (signature) {
      signeForEvent({
        id: event?.currentParticipant?.id,
        signature,
        signatureDate: new Date()
      });
      toast('Merci pour votre signature', {
        type: 'success'
      });
      setStep((prevStep) => prevStep + 1);
    }
  }, [signature]);

  return (
    <>
      <div className='bg-gris'>
        <header className='signature-header'>
          <h1 className='d-none d-lg-block'>{title}</h1>
          <h2 className='secondaryText'>Veuillez confirmer votre présence avec votre signature.</h2>
        </header>
        <div className='col-6 offset-3'>
          <SwitchWithText alterText='Saisir' firstText='Dessiner' onHandleChange={setSigChoice} />
        </div>
      </div>

      {!sigChoice ? <SignatureComponent getSignature={setSignature} /> : <TextSignature getSignature={setSignature} />}
    </>
  );
}

export default SignatureType;
