import styled from 'styled-components';
import breakpoint from 'components/styled/breakpoints/breakpoints';

const PasswordResetStyled = styled.section.attrs({
  className: 'password-reset-page'
})`
  text-align: center;
  height: 100%;

  .password-reset-page__container {
    position: relative;
  }

  .password-reset-page__header {
    margin-bottom: 35px;
  }

  .password-reset-page__form {
    text-align: left;
    margin-top: 40px;
  }

  /*  @media only screen and ${breakpoint.device.lg} {
    padding-top: 60px;
  }*/
`;

export default PasswordResetStyled;
PasswordResetStyled.displayName = 'PasswordResetStyled';
