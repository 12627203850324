import { format, intervalToDuration } from 'date-fns';
import { fr, enGB } from 'date-fns/locale';
import React from 'react';

export const formatMode = {
  edit: 'd/MM/yyyy',
  read: 'd MMMM yyyy',
  hour: 'HH:mm',
  api: 'yyyy-MM-dd',
  full: 'EEEE d MMMM yyyy'
};

export const dateFormatter = (date, dateFormat = 'read', locale = 'fr') => {
  const lang = {
    fr,
    enGB
  };
  if (date) {
    // api format doesn't need options
    if (dateFormat === formatMode.api) {
      return format(new Date(date), dateFormat);
    }
    return format(new Date(date), formatMode[dateFormat], {
      locale: lang[locale]
    });
  }
};

export const isValidDate = (d) => {
  return d instanceof Date && !isNaN(d);
};

export const daysHoursReminder = (endDate, prefix) => {
  const compareDate = intervalToDuration({
    start: new Date(),
    end: new Date(endDate)
  });

  if (compareDate.days === 0 && compareDate.hours === 0) {
    return (
      <span>
        {prefix ?? ''} {compareDate.minutes}{' '}
        {compareDate.minutes === 0 || compareDate.hours === 1 ? 'minute' : 'minutes'}
      </span>
    );
  }

  if (compareDate.days === 0 && compareDate.hours <= 23) {
    return (
      <span>
        {prefix ?? ''} {compareDate.hours} {compareDate.hours === 0 || compareDate.hours === 1 ? 'heure' : 'heures'}
      </span>
    );
  }

  return (
    <span>
      {prefix ?? ''} {compareDate.days} {compareDate.days === 0 || compareDate.days === 1 ? 'jour' : 'jours'}
    </span>
  );
};
