import React from 'react';
import CustomDataTable from 'components/styled/blocks/CustomDataTable';
import { PillInfo } from 'components/styled/pills/Pill';
import Card from 'components/styled/wrappers/Card';

const BundlesMobile = ({ bundles }) => {
  return (
    <Card className='d-xl-none'>
      <CustomDataTable>
        <div className='header-row'>
          <p className='header-row__item custom-table-data__column larger-column'>Unité</p>
          <p className='header-row__item custom-table-data__column'>Entrée</p>
          <p className='header-row__item custom-table-data__column'>Millièmes</p>
        </div>

        <div className='custom-table-data__content'>
          <ul className='custom-table-data__list'>
            {bundles.map((bundle) => {
              return (
                <li key={bundle.id} className='custom-table-data__list-item d-flex align-items-center'>
                  <div className='custom-table-data__column larger-column'>
                    <p className='custom-table-data__value'>{bundle.identifier}</p>
                    <p className='custom-table-data__type'>{bundle.category.name}</p>
                  </div>

                  <div className='custom-table-data__column'>{bundle.entranceNumber}</div>
                  <div className='custom-table-data__column'>
                    <PillInfo large>{bundle.thousandths}</PillInfo>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </CustomDataTable>
    </Card>
  );
};

export default BundlesMobile;
