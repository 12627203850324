import React from 'react';
import LoginForm from 'pages/login/LoginForm';
import LoginPageGlobal from 'components/styled/wrappers/login/LoginPageGlobal';

const Login = () => {
  return (
    <LoginPageGlobal showBackTo={false} classes='login-page-wrapper--login-init'>
      <LoginForm />
    </LoginPageGlobal>
  );
};

export default Login;
