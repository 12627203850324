import React from 'react';
import Lottie from 'react-lottie-player';
import * as hourglassData from './json/hourglass.json';
import * as loaderData from './json/loader.json';

export const Hourglass = ({ height, width }) => {
  return <Lottie loop animationData={hourglassData} play style={{ width, height }} />;
};

export const Loader = ({ height, width = 200 }) => {
  return (
    <div className='position-absolute top-50 start-50 translate-middle'>
      <Lottie loop animationData={loaderData} play style={{ width, height }} />
    </div>
  );
};
