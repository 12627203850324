import React from 'react';
import styled from 'styled-components';
import { CSS_GUIDE } from 'components/styled/constants';

const ResultLine = ({ resultValue, label, resultFor, total }) => {
  const percentage = resultValue > 0 ? (resultValue / parseFloat(total)) * 100 : 1;
  return (
    <ResultLineWrapper className={`boxRes result-line-wrapper--${resultFor}`}>
      <div className='result-line-top d-flex justify-content-between'>
        <h1>{label}</h1>
        <span>
          {resultValue} {resultValue > 1 ? 'millièmes' : 'millième'}
        </span>
      </div>

      <ProgressStyled className={`progress vote-progress vote-progress--${resultFor}`}>
        <ProgressBarStyled
          resultFor={resultFor}
          className='progress-bar'
          role='progressbar'
          style={{
            width: `${percentage}%`
          }}
          aria-valuenow={percentage}
          aria-valuemin='1'
          aria-valuemax='100'
        />
      </ProgressStyled>
    </ResultLineWrapper>
  );
};

export default ResultLine;

const ResultLineWrapper = styled.article`
  margin-top: 20px;

  .result-line-top {
    display: flex;
    h1 {
      color: ${CSS_GUIDE.colors.primary.hex};
    }
    span {
      font-weight: 700;
    }
  }

  &.result-line-wrapper--forVote span,
  &.result-line-wrapper--supplier span {
    color: ${CSS_GUIDE.colors.success};
  }

  &.result-line-wrapper--against span {
    color: ${CSS_GUIDE.colors.voteNo};
  }

  &.result-line-wrapper--abstention span {
    color: ${CSS_GUIDE.colors.voteAbstention};
  }
`;
ResultLineWrapper.displayName = 'ResultLineWrapper';

const ProgressStyled = styled.div`
  background-color: ${CSS_GUIDE.colors.bgGrey};
  border-radius: 15px;
`;
ProgressStyled.displayName = 'ProgressStyled';

const ProgressBarStyled = styled.div`
  border-radius: 15px;

  .vote-progress--forVote &.progress-bar,
  .vote-progress--supplier &.progress-bar {
    background-color: ${CSS_GUIDE.colors.success};
  }

  .vote-progress--abstention &.progress-bar {
    background-color: ${CSS_GUIDE.colors.voteAbstention};
  }

  .vote-progress--against &.progress-bar {
    background-color: ${CSS_GUIDE.colors.voteNo};
  }
`;
ProgressBarStyled.displayName = 'ProgressBarStyled';
