import React from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { Button } from 'components/styled/buttons/ButtonStyled';
import { toast } from 'react-toastify';
import { Link, useParams } from 'react-router-dom';

const SignatureComponent = ({ getSignature, showExit = false, setStep }) => {
  const { id } = useParams();
  const [sigPad, setSigPad] = React.useState({});
  const [trimmedDataURL, setTrimmedDataURL] = React.useState(null);
  const clear = () => {
    sigPad.clear();
    setTrimmedDataURL(null);
  };
  const trim = () => {
    if (sigPad.isEmpty()) {
      toast('Merci de signer avant de confirmer', {
        type: 'error'
      });
      return;
    }
    setTrimmedDataURL(sigPad.getTrimmedCanvas().toDataURL('image/jpg'));
    getSignature(sigPad.getTrimmedCanvas().toDataURL('image/jpg'));
  };
  return (
    <div className='ms-2 signature-component'>
      <p className='text-center'>Utiliser la souris ou votre doigt pour signer.</p>
      <SignatureCanvas
        backgroundColor='rgba(255,255,255, 1)'
        canvasProps={{ width: 300, height: 300, className: 'sigCanvas' }}
        ref={(ref) => {
          setSigPad(ref);
        }}
      />
      <div className='col-8 offset-2 text-center'>
        <button onClick={clear} className='btn btn-link col-6 signature-component__btn signature-component__btn-delete'>
          Effacer
        </button>
        <Button
          type='button'
          onClick={trim}
          disabled={!!trimmedDataURL}
          className={'signature-component__btn signature-component__btn-confirm'}
        >
          Confirmer et signer
        </Button>
        {showExit && (
          <>
            <Link className='btn btn-link' to={`/meetings/${id}/detail`}>
              Quitter
            </Link>
            <button className='btn btn-link' onClick={() => setStep(3)}>
              Éditer les vote
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default SignatureComponent;
