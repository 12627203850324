import styled from 'styled-components';
import img from 'assets/images/building-alpha.png';

const LoginVisualStyled = styled.aside.attrs({
  className: 'login-visual-styled'
})`
  width: 468px;
  justify-content: center;
  align-items: center;
  background: url(${img}) no-repeat right bottom;
  background-size: cover;
`;

export default LoginVisualStyled;
LoginVisualStyled.displayName = 'LoginVisualStyled';
