import React from 'react';
import styled from 'styled-components';
import { PillInfo } from 'components/styled/pills/Pill';
import Card from 'components/styled/wrappers/Card';
import breakpoint from 'components/styled/breakpoints/breakpoints';
import { CSS_GUIDE } from 'components/styled/constants';

const BundleRow = ({ className, bundle, tenants }) => {
  return (
    <Card stripped>
      <div className={`row ${className}`}>
        <div className='col-3 bundle-row__cell'>
          <p className='custom-table-data__value'>{bundle.identifier}</p>
          <p className='custom-table-data__type'>{bundle.category.name}</p>
        </div>

        <div className='col-1 bundle-row__cell'>
          <PillInfo large>{bundle.entranceNumber}</PillInfo>
        </div>

        <div className='col-1 bundle-row__cell'>
          <PillInfo large>{bundle.thousandths}</PillInfo>
        </div>

        <div className='col-2 bundle-row__cell'>01/01/2015</div>

        <div className='col-5 bundle-row__cell'>
          {tenants &&
            tenants.length > 0 &&
            tenants.map((tenant, index) => {
              return <p key={index}>Tenant {index + 1}</p>;
            })}
        </div>
      </div>
    </Card>
  );
};

const BundleRowStyled = styled(BundleRow).attrs({
  className: 'bundle-row-styled'
})`
  padding: 25px 10px;

  .bundle-row__cell {
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    //align-items: center;
    justify-content: center;
    &:last-child {
      text-align: left;
    }
  }

  .bundle-row__cell + .bundle-row__cell {
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: -5px;
      left: 0;
      width: 1px;
      height: 50px;
      background-color: ${CSS_GUIDE.colors.primary.hex};
    }
  }

  /*@media only screen and ${breakpoint.device.xl} {
  }*/
`;
export default BundleRowStyled;
BundleRowStyled.displayName = 'BundleRowStyled';
