import { apiCreator } from 'api/baseApi';
import { MEETING_EVENT_PARTICIPANTS_ROLE } from 'constants/api.constants';

const endPoints = (build) => ({
  getMeetingRoles: build.query({
    query: () => ({
      url: MEETING_EVENT_PARTICIPANTS_ROLE
    })
  })
});

export const meetingStaticDataApi = apiCreator({
  reducerPath: 'meetingStaticDataApi',
  endPointsConfigFn: endPoints
});

export const { useGetMeetingRolesQuery } = meetingStaticDataApi;
