import styled from 'styled-components';
import breakpoint from '../breakpoints/breakpoints';
// import { CSS_GUIDE } from '../constants';

const space = 220;

const GenericPageWrapper = styled.div.attrs({
  className: 'generic-page-wrapper'
})`
  padding-top: ${space}px;
  position: relative;

  &:before {
    content: '';
    display: block;
    height: ${space}px;
    background-image: linear-gradient(173.16deg, #42aecc -33.81%, #006083 65.4%);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: -2;
  }

  .dashboard__header {
    text-align: center;
  }

  .participation-form__owners {
    margin-left: -15px;
    margin-right: -15px;
    padding: 15px 20px 15px 15px;
    background: #fafafa;

    .root-radio-label + .root-radio-label {
      position: relative;
      padding-top: 20px;
      padding-bottom: 20px;
    }

    .root-radio-label + .root-radio-label:before {
      content: '';
      display: block;
      width: 80%;
      height: 1px;
      background: #959595;
      position: absolute;
      top: 0;
    }
  }

  .proxy-toggle {
    padding-left: 10px;
  }

  .generic-page-wrapper__view-zone {
    position: absolute;
    top: 40px;
  }

  .dashboard-logo {
    max-width: none;
    max-height: none;
    width: 100px;
    height: 120px;
  }

  .residence-view-zone {
    top: 100px;

    #svg-building {
      fill: #ffffff;
      max-height: 86px;
      max-width: 86px;
    }

    .headline-3 {
      color: #ffffff;
    }

    .pill-ref-styled {
      top: -15px;
    }

    .custom-link-to-styled {
      top: -50px;
      width: 40px;
      height: 40px;
    }
  }

  .block-section__custom-container {
    padding: 0 15px 20px 0.75rem;
  }

  &.residence-page .top-section {
    margin-top: -100px;
  }

  @media only screen and ${breakpoint.device.lg} {
    padding-top: 0;
    margin-left: calc(-60px - 0.5rem);

    &.residence-page .top-section {
      margin-top: 0;
    }
  }
`;

export default GenericPageWrapper;
GenericPageWrapper.displayName = 'GenericPageWrapper';
