import React, { useContext } from 'react';
import { toast } from 'react-toastify';

const ToasterContext = React.createContext(() => {});

export const useToaster = () => {
  return useContext(ToasterContext);
};

const ToasterProvider = (props) => {
  const notify = (arg, options) =>
    toast(arg, {
      autoClose: false,
      pauseOnHover: true,
      ...options
    });

  return <ToasterContext.Provider value={{ notify }} {...props} />;
};

export default ToasterProvider;
