import styled from 'styled-components';
import { CSS_GUIDE } from 'components/styled/constants';

const encodedErrorIcon = `url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M10.0001 1.6665C5.40008 1.6665 1.66675 5.39984 1.66675 9.99984C1.66675 14.5998 5.40008 18.3332 10.0001 18.3332C14.6001 18.3332 18.3334 14.5998 18.3334 9.99984C18.3334 5.39984 14.6001 1.6665 10.0001 1.6665ZM10.8334 14.1665H9.16675V12.4998H10.8334V14.1665ZM10.8334 10.8332H9.16675V5.83317H10.8334V10.8332Z' fill='%23DF3030' /%3E%3C/svg%3E") `;

const encodedValidIcon = `url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.2' d='M20.1666 11.0002C20.1666 16.0625 16.0622 20.1668 10.9999 20.1668C5.93763 20.1668 1.83325 16.0625 1.83325 11.0002C1.83325 5.93787 5.93763 1.8335 10.9999 1.8335C16.0622 1.8335 20.1666 5.93787 20.1666 11.0002Z' fill='%230BC196'/%3E%3Cpath d='M15.8519 6.68506L9.6323 12.912L7.06472 10.3517L5.76855 11.6479L9.63414 15.5043L17.1485 7.98123L15.8519 6.68506Z' fill='%23089171'/%3E%3C/svg%3E") `;

export const TextFieldBase = styled.input`
  height: 50px;
  width: 100%;
  border-radius: 15px;
  outline: none;
  padding-left: 20px;
  padding-right: 30px;
  border: 1px solid ${({ hasError }) => (hasError ? CSS_GUIDE.colors.error : CSS_GUIDE.colors.bgGrey)};
  background-color: ${({ disabled }) => (disabled ? CSS_GUIDE.colors.bgGrey : '#ffffff')};
  ${({ status }) => {
    if (status === 'error' || status === 'valid') {
      return `
    background-repeat: no-repeat;
    background-position: calc(100% - 15px) center;
    background-image: ${status === 'error' ? encodedErrorIcon : encodedValidIcon}
  `;
    }
  }};
  & + & {
    margin-top: ${CSS_GUIDE.space.vertical.input}px;
  }
  &:focus {
    box-shadow: 0 0 0 2px rgb(220 220 220 / 50%);
    border: 1px solid ${CSS_GUIDE.colors.primary.hex};
  }
  //background-repeat: no-repeat;
`;

export const TextFieldStyled = styled(TextFieldBase).attrs({
  className: 'text-field-styled'
})`
  padding-left: 30px;
`;

export const TextFieldInline = styled.input.attrs({
  type: 'text',
  className: 'text-field-inline-styled'
})`
  height: 50px;
  outline: none;
  border-bottom: 1px solid #eaeaea;
  border-width: 0 0 1px 0;
  width: 15%;
  text-align: center;
  background: transparent;
  font-size: 34px;
  padding: 0;
  //color: #ffffff;
`;
