import React from 'react';
import { Button, ButtonLight } from 'components/styled/buttons/ButtonStyled';
import AppIcon from 'components/icons/AppIcon';
import { TextFieldStyled } from 'components/styled/forms/TextField';
import SearchField from 'components/styled/forms/SearchField';
import { PillInfo, PillRef } from 'components/styled/pills/Pill';
import Card from 'components/styled/wrappers/Card';
import ResidenceBlock from 'pages/dashboard/components/residence/ResidenceBlock';
import TextWithIcon from 'components/commun/TextWithIcon';
import MeetingItemComponent from 'pages/dashboard/components/meetingItems/MeetingItemComponent';
// import RadioButton from 'components/forms/RadioButton';
import SwitchCheckbox from 'components/forms/SwitchCheckbox';
import SwitchWithText from 'components/forms/SwitchWithText';
import useModal from 'hooks/useModal';
import ModalComponent, { MODAL_TYPE } from 'components/modals/ModalComponent';
import { longTextDemo } from 'components/styled/longTextDemo';
import RadioButtonComponent from 'components/forms/RadioButtonComponent';

const StyledGuide = () => {
  const { toggle, isOpen, isLocked, closeModal } = useModal(true);
  const { toggle: toggle2, isOpen: isOpen2, isLocked: isLocked2, closeModal: closeModal2 } = useModal();
  return (
    <div className={'aea-styled-guide'}>
      <h1>Styled components</h1>
      <Button onClick={() => console.log('test')}>Button de Yannick Emr</Button>
      <Button $positive>Button Alt</Button>

      <ButtonLight onClick={toggle} large>
        Open a Modal
      </ButtonLight>

      <Button $positive onClick={toggle2} large>
        Open Full screen Modal
      </Button>

      <ModalComponent modalProps={{ toggle, isOpen, isLocked, closeModal }}>
        <p>{longTextDemo}</p>
      </ModalComponent>

      <ModalComponent
        type={MODAL_TYPE.FULL_SCREEN}
        modalProps={{ toggle: toggle2, isOpen: isOpen2, isLocked: isLocked2, closeModal: closeModal2 }}
      >
        <Button onClick={closeModal2}>FERMER FULL SCREEN</Button>
        <p>{longTextDemo}</p>
      </ModalComponent>

      <Card>
        <PillRef>
          <span>BA008</span>
        </PillRef>

        <PillInfo>Assemblée générale ordinaire</PillInfo>
      </Card>

      <ResidenceBlock isDashboard />
      <ResidenceBlock />

      {/*      <MeetingItemComponent />
      <MeetingItemComponent meetingStatus={'pending'} />*/}

      <section className='forms-styled'>
        <h2 className='h2'>Forms</h2>
        <TextFieldStyled status={'valid'} placeholder='Type something' />
        <SearchField status={'valid'} />

        {/*
        <RadioButton value='radio1' labelText='Radio 1' name={'radiosa'} block />
        <RadioButton value='radio2' labelText='Radio 2' name={'radiosa'} />*/}

        <h3>Radio button</h3>
        <fieldset>
          <RadioButtonComponent
            labelText='Oui, en présence'
            name={'presence'}
            value={true}
            isBlock
            checked={true}
            isSelected={true}
            classes='mb-3'
            onChange={() => {}}
          />

          <RadioButtonComponent
            labelText='Non'
            name={'presence'}
            value={false}
            isBlock
            isSelected={true}
            classes='mb-3'
            onChange={() => {}}
          />

          <RadioButtonComponent
            labelText='Abstention'
            name={'presence'}
            value={null}
            isBlock
            isSelected={true}
            classes='mb-3'
            onChange={() => {}}
          />

          <RadioButtonComponent
            onChange={() => {}}
            labelText='Simple Radio'
            name={'presence'}
            value={'simple'}
            classes='mb-3'
          />
          <RadioButtonComponent
            labelText='Reverse Label Radio'
            name={'presence'}
            value={'reverse'}
            classes='mb-3'
            reverse
            onChange={() => {}}
          />
        </fieldset>

        <SwitchCheckbox id={'Geo'} />

        <Card>
          <SwitchWithText alterText='Cliche' firstText='Draw a tag and other' />
        </Card>
      </section>

      <Card>
        <TextWithIcon iconName={'phone'}>Leudelange</TextWithIcon>
        <TextWithIcon iconName={'assemblees'}>Les assemblees generales</TextWithIcon>
      </Card>

      <section className='icons' style={{ background: 'lightgray' }}>
        <h2 className='h2'>Icons</h2>
        <AppIcon name='assemblees' />
        <AppIcon name='calendar' />
        <AppIcon name='clock' />
        <AppIcon name='dashboard' />
        <AppIcon name='download' />
        <AppIcon name='eyes-close' />
        <AppIcon name='eyes' />
        <AppIcon name='folder' />
        <AppIcon name='filter' />
        <AppIcon name='heart' />
        <AppIcon name='info' />
        <AppIcon name='location' />
        <AppIcon name='negative' />
        <AppIcon name='residences' />
        <AppIcon name='success' />
        <AppIcon name='trash' />
        <AppIcon name='user' />
        <AppIcon name='warning' />
        <AppIcon name='add' />
        <AppIcon name='arrow' />
        <AppIcon name='cross' />
        <AppIcon name='filter-order' />
        <AppIcon name='list-view' />
        <AppIcon name='mail' />
        <AppIcon name='notification' />
        <AppIcon name='phone' />
        <AppIcon name='comment' />
        <AppIcon name='search' />
        <AppIcon name='square' />
        <AppIcon name='alert-error-input' />
        <AppIcon name='check-valid-input' />
        <AppIcon name='cross-field' />
        <AppIcon name='check-cross' />
        <AppIcon name='search-form' />
        <AppIcon name='logout' />
        <AppIcon name='radio-btn' />
        <AppIcon name='radio-btn-selected' />
        <AppIcon name='check-thin' />
        <AppIcon name='vote-yes' />
        <AppIcon name='vote-no' />
        <AppIcon name='vote-box' />
        <AppIcon name='free-discuss' />
        <AppIcon name='smiley-face' />
        <AppIcon name='on-boarding-comment' />
        <AppIcon name='building' />
        <AppIcon name='useful-info' />
      </section>
    </div>
  );
};

export default StyledGuide;
