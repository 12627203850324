import { apiCreator } from 'api/baseApi';
import { MEETING_EVENT_PARTICIPANTS, MEETING_INVITATIONS_BY_ID } from 'constants/api.constants';

const endPoints = (build) => ({
  //MEETING_EVENT_PARTICIPANTS
  signeForEvent: build.mutation({
    query: ({ id, ...patch }) => ({
      url: MEETING_EVENT_PARTICIPANTS(id),
      method: 'PATCH',
      data: patch
    })
  }),
  confirmConvocation: build.mutation({
    query: ({ id, data }) => ({
      url: MEETING_INVITATIONS_BY_ID(id),
      method: 'PATCH',
      data
    })
  })
});

export const meetingEventParticipantApi = apiCreator({
  reducerPath: 'meetingEventParticipantApi',
  endPointsConfigFn: endPoints
});

export const { useSigneForEventMutation, useConfirmConvocationMutation } = meetingEventParticipantApi;
