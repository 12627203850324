import React from 'react';
import { SwitchInput } from 'components/styled/forms/toggleSwitch/Switch';

const SwitchCheckbox = ({ id, toggled, onChange, text, disabled }) => {
  return (
    <div className='switch-checkbox-wrapper'>
      <SwitchInput
        disabled={disabled}
        className='switch-checkbox'
        id={id}
        type='checkbox'
        checked={toggled}
        onChange={onChange}
      />
      {text && (
        <label htmlFor={id} onClick={!disabled ? onChange : null} disabled={disabled}>
          {text}
        </label>
      )}
    </div>
  );
};

export default SwitchCheckbox;
