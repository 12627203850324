import React from 'react';
import { Button } from 'components/styled/buttons/ButtonStyled';
import { TextFieldBase } from 'components/styled/forms/TextField';
import { toast } from 'react-toastify';
import { Link, useParams } from 'react-router-dom';

const TextSignature = ({ getSignature, showExit = false, setStep }) => {
  const { id } = useParams();
  const [sigText, setSigText] = React.useState('');
  const [imgSig, setImgSig] = React.useState(null);
  let canvasTxt;
  React.useEffect(() => {
    canvasTxt = document.getElementById('canvasComponent').getContext('2d');
    if (sigText.length > 0) {
      canvasTxt.font = '50px Herr Von Muellerhoff';
      canvasTxt.clearRect(0, 0, 300, 300);
      canvasTxt.fillText(sigText, 30, 100);
      setImgSig(canvasTxt.canvas.toDataURL('image/jpg'));
    }
  }, [sigText]);

  const clear = () => {
    document.getElementById('canvasComponent').getContext('2d').clearRect(0, 0, 300, 300);
    setImgSig(null);
    setSigText('');
  };
  const send = () => {
    if (sigText === '') {
      toast('Merci de signer avant de confirmer', {
        type: 'error'
      });
    }
    if (imgSig) getSignature(imgSig);
  };
  return (
    <div className='ms-2 me-2'>
      <TextFieldBase
        type='text'
        placeholder='Votre nom et prénom pour la signature'
        value={sigText}
        onChange={(e) => setSigText(e.target.value)}
        maxLength='14'
      />

      <canvas id='canvasComponent' style={{ display: 'none' }} width='300' height='200'>
        Your browser does not support the HTML5 canvas tag.
      </canvas>

      <div className='canvasDisplay'>
        <img src={imgSig} className='sigTexT' />
      </div>
      <div className='col-8 offset-2 text-center'>
        {imgSig && (
          <button onClick={clear} className='btn btn-link col-6'>
            Effacer
          </button>
        )}
        <Button onClick={send} disabled={sigText === ''}>
          Confirmer et signer
        </Button>
        {showExit && (
          <>
            <Link className='btn btn-link' to={`/meetings/${id}/detail`}>
              Quitter
            </Link>
            <button className='btn btn-link' onClick={() => setStep(3)}>
              Éditer les vote
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default TextSignature;
