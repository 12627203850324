import { apiCreator } from 'api/baseApi';
import { MEETING_VOTE, MEETING_VOTE_BY_ID, MEETING_VOTE_ELECTION, MEETING_VOTE_RESULTS } from 'constants/api.constants';

const endPoints = (build) => ({
  voteElection: build.mutation({
    query: ({ data }) => ({
      url: MEETING_VOTE_ELECTION,
      method: 'POST',
      data
    })
  }),
  sendVoteData: build.mutation({
    query: ({ data }) => ({
      url: MEETING_VOTE,
      method: 'POST',
      data
    })
  }),

  updateVoteData: build.mutation({
    query: ({ id, data }) => ({
      url: MEETING_VOTE_BY_ID(id),
      method: 'PATCH',
      data
    })
  }),
  getMeetingVotes: build.query({
    query: ({ page, perPage, item, participant, participantIds }) => ({
      params: { perPage, page, item, participant, 'participant[]': participantIds },
      url: MEETING_VOTE,
      method: 'GET'
    })
  }),
  getVoteResults: build.query({
    query: (id) => ({
      url: MEETING_VOTE_RESULTS(id),
      method: 'GET'
    })
  })
});

export const meetingVoteApi = apiCreator({
  reducerPath: 'meetingVoteApi',
  endPointsConfigFn: endPoints
});

export const {
  useVoteElectionMutation,
  useSendVoteDataMutation,
  useGetVoteResultsQuery,
  useGetMeetingVotesQuery,
  useUpdateVoteDataMutation
} = meetingVoteApi;
