import React from 'react';
import MainSubContainer from 'components/styled/wrappers/MainSubContainer';
import { useGetMeetingByIdQuery } from 'api/services/meetingApi';
import { useParams } from 'react-router-dom';
import PageTitle from 'components/commun/PageTitle';
import { Loader } from 'components/loader';

const MeetingPageWrapper = ({ pageTitleOtherText, hasBackTo, children, subText }) => {
  const { id: meetingId } = useParams();
  const { data: meetingData, isLoading } = useGetMeetingByIdQuery(meetingId);

  if (isLoading) {
    return <Loader />;
  }
  return (
    <MainSubContainer>
      <PageTitle meeting={meetingData} otherText={pageTitleOtherText} hasBackTo={hasBackTo} subText={subText} />
      {children(meetingData)}
    </MainSubContainer>
  );
};

export default MeetingPageWrapper;
