import React from 'react';
import styled from 'styled-components';
import AppIcon from 'components/icons/AppIcon';
import { HeadlineStyled3 } from 'components/styled/headings/HeadlineStyled';
import Card from 'components/styled/wrappers/Card';
import { CSS_GUIDE } from 'components/styled/constants';
import breakpoints from 'components/styled/breakpoints/breakpoints';

const MeetingInfoBlock = ({ value, altIcon, infoType }) => {
  const infoTypeObj = {
    date: {
      label: 'Date',
      icon: 'clock'
    },
    location: {
      label: 'Lieu',
      icon: 'location'
    }
  };

  const locationFormatter = (location) => {
    const meetingLocationType = location?.type.split('/').pop();
    return (
      <address className='meeting-info-block__location'>
        {meetingLocationType === '1' ? (
          <p>85 rue du Golf, L-1638 Senningerberg, G.-D. Luxembourg</p>
        ) : (
          <>
            <p>
              <span>{location?.number} </span>
              <span>{location?.street} </span>
              <span> {location?.zip} </span>
            </p>
            <p>
              <span> {location?.city} </span>
            </p>
          </>
        )}
      </address>
    );
  };

  return (
    <Card>
      <MeetingInfoBlockStyled className={'meeting-info-block--styled'}>
        <div className={'meeting-info-block__icon'}>
          <AppIcon name={infoTypeObj[infoType]?.icon} classes={`svg-${infoTypeObj[infoType]?.icon}`} />
        </div>

        <div className='meeting-info-block__detail'>
          <HeadlineStyled3 $weight={'bold'}>{infoTypeObj[infoType]?.label}</HeadlineStyled3>
          {infoType === 'location' ? locationFormatter(value) : <p>{value}</p>}
        </div>

        {altIcon && (
          <div className='icon-block--alt'>
            <AppIcon name={'clock'} />
          </div>
        )}
      </MeetingInfoBlockStyled>
    </Card>
  );
};

export default MeetingInfoBlock;

const MeetingInfoBlockStyled = styled.div`
  display: flex;
  align-items: center;

  color: ${CSS_GUIDE.colors.primary.hex};

  .meeting-info-block__icon {
    background-color: ${CSS_GUIDE.colors.primary.rgba};
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 200px;
    margin-right: 20px;
    flex-shrink: 0;
  }

  .aea-svg {
    fill: currentColor;
  }

  .svg-clock {
    width: 26px;
    height: 26px;
  }

  .svg-location {
    max-width: 50px;
    max-height: 50px;
  }

  address {
    margin-bottom: 0;
  }

  .meeting-info-block__detail p {
    margin: 0;
  }

  @media only screen and ${breakpoints.device.lg} {
    .meeting-info-block__icon {
      width: 90px;
      height: 90px;
    }

    .svg-location {
      max-width: 60px;
      max-height: 60px;
    }
  }
`;
