import axios from 'axios';

export const downloadAnyDocument = async ({ url, method, params, data, fileName, download = true }) => {
  const token = window.sessionStorage.getItem('token');
  try {
    return await axios({
      url: process.env.REACT_APP_API_BASEURL + url,
      method,
      data,
      params: params,
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Accept-Language': '*'
      }
    }).then((response) => {
      if (download) {
        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data], {
            type: response.data.type
          })
        );
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
      return response;
    });
  } catch (axiosError) {
    console.error(axiosError);
  }
};
