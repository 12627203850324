import { useState } from 'react';

const useModal = (isLocked) => {
  const [isOpen, setIsOpen] = useState();

  function toggle() {
    setIsOpen(!isOpen);
  }

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  return {
    isOpen,
    toggle,
    openModal,
    closeModal,
    isLocked
  };
};

export default useModal;
