import React from 'react';
import { useParams } from 'react-router-dom';
import GenericPageWrapper from 'components/styled/wrappers/GenericPageWrapper';
import { BlockSection } from 'components/styled/blocks/BlockSection';
import MainSubContainer from 'components/styled/wrappers/MainSubContainer';
import CircleNumber from 'components/styled/atoms/CircleNumber';
import { useGetAllDocumentsByTypeQuery } from 'api/services/documentApi';

const FilesPage = () => {
  const { type, id } = useParams();

  const { data } = useGetAllDocumentsByTypeQuery({ type, joint_ownership: id });

  return (
    <GenericPageWrapper className='dashboard-page row'>
      <nav className='files-count-zone generic-page-wrapper__view-zone d-flex justify-content-center d-lg-none align-items-center'>
        <CircleNumber>34</CircleNumber> Files
      </nav>
      <BlockSection className='block-section block-section-files'>
        <MainSubContainer className='row'>
          <div className='container-lg block-section__container'>Main files</div>
        </MainSubContainer>
      </BlockSection>
    </GenericPageWrapper>
  );
};

export default FilesPage;
