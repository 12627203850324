import React from 'react';
import ModalBase from 'components/modals/ModalBase';
import ModalFullScreen from './ModalFullScreen';

export const MODAL_TYPE = {
  FULL_SCREEN: 'fullScreen',
  STYLED: 'style'
}

const ModalComponent = ({ type, children, modalProps, ...restProps }) => {
  switch (type) {
    case MODAL_TYPE.STYLED:
      return <div>Styled Modal</div>;

    case MODAL_TYPE.FULL_SCREEN:
      return <ModalFullScreen modalProps={modalProps} {...restProps}>{children}</ModalFullScreen>;

    default:
      return <ModalBase {...modalProps} {...restProps}>{children}</ModalBase>;
  }
};

export default ModalComponent;
