import React, { useState } from 'react';
import NavLinks from 'components/layout/nav/navLinks';
import BurgerStyled from 'components/styled/buttons/BurgerStyled';
import styled from 'styled-components';
import { CSS_GUIDE } from 'components/styled/constants';
import AppIcon from 'components/icons/AppIcon';
import AppLogo from 'components/commun/AppLogo';
import { useAuthContext } from 'auth/ProvideAuth';
import { IconLabelButtonStyled } from 'components/styled/buttons/ButtonStyled';

export default function MobileNav() {
  const { signOut } = useAuthContext();
  const [showNav, setShowNav] = useState(false);

  return (
    <MobileNavWrapper id={'mobile-nav'} className='d-xs-block d-lg-none mobile-nav-component'>
      <BurgerStyled onClick={() => setShowNav(!showNav)} showNav={showNav} />

      <MobileNavBackDrop
        className={`mobile-nav-backdrop ${showNav ? 'mobile-nav-backdrop--show' : ''}`}
        onClick={() => setShowNav(false)}
        showNav={showNav}
      />

      <MobileNavStyled className={`mobile-nav ${showNav ? 'mobile-nav--show' : ''}`}>
        <div className='mobile-nav-inner'>
          <AppLogo classes={'mobile-nav__logo'} />
          <AppIcon name={'cross'} onClick={() => setShowNav(false)} />
          <NavLinks device={'mobile'} onSetShowNav={setShowNav} />

          <div className='mobile-nav-footer'>
            <IconLabelButtonStyled iconName={'logout-blue'} handleOnClick={signOut} label={'Se déconnecter'} />
          </div>
        </div>
      </MobileNavStyled>
    </MobileNavWrapper>
  );
}

const MobileNavBackDrop = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0);

  &.mobile-nav-backdrop--show {
    z-index: 15;
    background: rgba(0, 0, 0, 0.5);
  }
`;

const MobileNavStyled = styled.nav.attrs({
  className: 'mobile-nav-styled'
})`
  transform: translateX(-100%);
  position: fixed;
  width: 80%;
  height: 100%;
  z-index: 20;
  display: flex;
  transition: all ease-in-out 0.4s;
  background-color: #ffffff;

  .mobile-nav-inner {
    padding: 40px 15px;
    width: 100%;
    position: relative;
  }

  &.mobile-nav--show {
    transform: translateX(0);
  }

  .mobile-nav__links {
    margin-bottom: 0;
    width: 100%;
    padding-top: 130px;
  }

  .mobile-nav__link-item {
    .nav-to-link-styled {
      background-color: transparent;
      color: ${CSS_GUIDE.colors.black};
      box-shadow: none;
      padding-left: 5px;

      &.active {
        color: ${CSS_GUIDE.colors.primary.hex};
        font-weight: 500;
      }

      span:after {
        content: '';
        display: block;
        height: 1px;
        width: 87px;
        background-color: ${CSS_GUIDE.colors.primary.hex};
      }
    }

    &:last-child .nav-to-link-styled {
      span:after {
        display: none;
      }
    }

    .aea-svg {
      fill: currentColor;
    }
  }

  #svg-cross {
    position: absolute;
    right: 15px;
    top: 70px;
  }

  .logout-wrapper {
    border-radius: 50px;
    background-color: ${CSS_GUIDE.colors.primary.hex};
    display: flex;
    align-items: center;
  }

  .mobile-nav__logo {
    position: absolute;
    width: 60px;
  }

  .mobile-nav-footer {
    margin-top: 100px;

    span {
      margin-left: 10px;
      color: ${CSS_GUIDE.colors.primary.hex};
    }

    .aea-svg.logout-blue {
      width: 35px;
      height: 35px;
    }
  }
`;

MobileNavStyled.displayName = 'MobileNavStyled';

const MobileNavWrapper = styled.div.attrs({
  className: 'mobile-nav-wrapper'
})`
  position: relative;
`;
MobileNavWrapper.displayName = 'MobileNavWrapper';
