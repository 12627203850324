import React from 'react';
import CustomDataTable from 'components/styled/blocks/CustomDataTable';
import { PillInfo } from 'components/styled/pills/Pill';
import ListHeadStyled from 'components/styled/blocks/ListHeadStyled';
import BundleRowStyled from 'pages/residencePage/components/BundleRowStyled';

const BundlesDesktop = ({ bundles, tenants }) => {
  return (
    <div className='d-none d-xl-block'>
      <ListHeadStyled className='container-fluid'>
        <div className='row'>
          <div className='col-3 text-center'>Unités</div>
          <div className='col-1 text-center'>Entrée</div>
          <div className='col-1 text-center'>Millièmes</div>
          <div className='col-2 text-center'>Depuis le</div>
          <div className='col-5 px-4'>Locataires(s) actuel(s)</div>
        </div>
      </ListHeadStyled>

      <CustomDataTable>
        <ul className='custom-table-data__list bundles-tenants__list'>
          {bundles.map((bundle) => {
            return (
              <li key={bundle.id} className='custom-table-data__list-item bundles-tenants__list-item'>
                <BundleRowStyled bundle={bundle} tenants={tenants} />
              </li>
            );
          })}
        </ul>
      </CustomDataTable>
    </div>
  );
};

export default BundlesDesktop;
