import React from 'react';
import { useParams } from 'react-router-dom';
import GenericPageWrapper from 'components/styled/wrappers/GenericPageWrapper';
import AppIcon from 'components/icons/AppIcon';
import { useGetBundlesTenantsQuery, useGetResidenceByIdQuery } from 'api/services/jointOwnershipApi';
import { HeadlineStyled1, HeadlineStyled3 } from 'components/styled/headings/HeadlineStyled';
import { BlockSection } from 'components/styled/blocks/BlockSection';
import MainSubContainer from 'components/styled/wrappers/MainSubContainer';
import { PillRef } from 'components/styled/pills/Pill';
import GenericRowWrapper from 'components/styled/wrappers/GenericRowWrapper';
import BundlesMobile from 'pages/residencePage/components/bundlesMobile';
import BundlesDesktop from 'pages/residencePage/components/bundlesDesktop';
import { useGetAllMeetingsQuery } from 'api/services/meetingApi';
import MeetingItems from 'pages/dashboard/components/meetingItems';
import { documents } from 'constants/documentsFolder';
import FolderStyled from 'components/styled/molecules/FolderStyled';
import CustomLinkToStyled from 'components/styled/buttons/CustomLinkToStyled';
import { Loader } from 'components/loader';

const ResidencePage = () => {
  const { id } = useParams();
  const { data: residenceInfo } = useGetResidenceByIdQuery(id, {
    skip: !id
  });

  const { data: bundlesTenants, isLoading: isLoadingBundlesTenants } = useGetBundlesTenantsQuery(id);

  const { data: meetings, isLoading: isLoadingMeetings, error: errorsMeetings } = useGetAllMeetingsQuery();

  return (
    <GenericPageWrapper className='residence-page row'>
      <div className='generic-page-wrapper__view-zone residence-view-zone d-flex flex-column justify-content-center d-lg-none align-items-center'>
        <HeadlineStyled3 $weight={500}>{residenceInfo?.name}</HeadlineStyled3>
        <AppIcon name='building' />
        {residenceInfo?.code && <PillRef large>{residenceInfo.code}</PillRef>}
        <CustomLinkToStyled iconName='arrow' to='/' />
      </div>

      <BlockSection className='top-section'>
        <MainSubContainer>
          <div className='container-lg block-section__container'>
            <div className='page-top-zone d-none d-lg-flex'>
              <CustomLinkToStyled iconName='arrow' to='/' />

              <div className='page-top-zone__headline d-lg-flex flex-column'>
                <div className='page-top-zone__headline-title d-flex align-items-center mb-2'>
                  <HeadlineStyled1 $weight='medium' className='mb-0 me-2'>
                    Résidence {residenceInfo?.name}
                  </HeadlineStyled1>
                  {residenceInfo?.code && <PillRef large>{residenceInfo.code}</PillRef>}
                </div>

                {residenceInfo?.address && (
                  <p className='page-top-zone__headline-subtitle'>
                    {residenceInfo.address?.number}, {residenceInfo.address?.street}, {residenceInfo.address?.zip}{' '}
                    {residenceInfo.address?.city}
                  </p>
                )}
              </div>
            </div>

            <HeadlineStyled3 className='block-section__title' $weight={700}>
              Mes unités
            </HeadlineStyled3>

            {/*<GenericRowWrapper>*/}
            {isLoadingBundlesTenants && <Loader width={50} />}
            {bundlesTenants?.bundles && <BundlesMobile bundles={bundlesTenants.bundles} />}
            {bundlesTenants?.bundles && (
              <BundlesDesktop bundles={bundlesTenants.bundles} tenants={bundlesTenants?.tenants} />
            )}
            {/*</GenericRowWrapper>*/}
          </div>
        </MainSubContainer>
      </BlockSection>

      <BlockSection className='mid-section'>
        <div className='row'>
          <div className='container-lg block-section__container'>
            {!isLoadingMeetings && !errorsMeetings && <MeetingItems meetings={meetings} />}
          </div>
        </div>
      </BlockSection>

      <BlockSection className='low-section block-section block-section-contacts'>
        <MainSubContainer className='row'>
          <div className='container-lg block-section__container'>
            <HeadlineStyled3 $weight={700} className='mb-5'>
              Fichiers
            </HeadlineStyled3>
            <div className='row'>
              {documents.map((doc) => {
                return (
                  <div className='col-6 col-md-3 col-lg-4 col-xl-3 col-xxl-2 mb-4' key={doc.type}>
                    <FolderStyled className='' doc={doc} jointOwnershipId={id} />
                  </div>
                );
              })}
            </div>
          </div>
        </MainSubContainer>
      </BlockSection>
    </GenericPageWrapper>
  );
};

export default ResidencePage;
