import React from 'react';
import { Link } from 'react-router-dom';
import Card from 'components/styled/wrappers/Card';
import AppIcon from 'components/icons/AppIcon';
import { ResidenceLocationStyled, ResidenceRefStyled } from 'components/styled/molecules/Residence';
import { PillRef } from 'components/styled/pills/Pill';
// import 'pages/dashboard/components/residence/residenceBlock.css';
import TextWithIcon from 'components/commun/TextWithIcon';

const ResidenceBlock = ({ isDashboard = false, residence }) => {
  const renderNormalBlock = (
    <div className='residence-item'>
      <ResidenceRefStyled className='residence-block__reference'>
        <AppIcon name='building' />
        <PillRef pillType='residence'>BA008</PillRef>
      </ResidenceRefStyled>

      <ResidenceLocationStyled className='residence-block__info'>
        <AppIcon name='location' />
        <p>{residence?.city}</p>
      </ResidenceLocationStyled>
    </div>
  );

  const renderBlockFromDashboard = (
    <div className='row'>
      <div className='residence-block__col residence-block__first col-lg-3 d-lg-flex justify-content-center align-items-center'>
        <ResidenceRefStyled className='residence-block__reference'>
          <AppIcon name='building' />
          <PillRef pillType='components' className='pill-ref'>
            {residence?.code}
          </PillRef>
        </ResidenceRefStyled>

        <ResidenceLocationStyled className='residence-block__location' isDashboard={isDashboard}>
          <p className='stronger-text'>{residence?.name}</p>
          <TextWithIcon otherClassName='d-lg-none' iconName='location'>
            {residence?.city}
          </TextWithIcon>
        </ResidenceLocationStyled>
      </div>

      <div className='residence-block__col residence-block__second col-lg-3 d-none d-lg-flex justify-content-center align-items-center'>
        <span className='text-center'>{residence?.address_fulltext}</span>
      </div>

      <div className='residence-block__col residence-block__third col-2 d-none d-lg-flex justify-content-center align-items-center'>
        <span>001 - Cave</span>
      </div>

      <div className='residence-block__col residence-block__fourth col-4 d-none d-lg-flex justify-content-center align-items-center'>
        <span>N.A</span>
      </div>

      <AppIcon name='arrow' classes='d-none d-lg-block' />
    </div>
  );

  return isDashboard ? (
    <Link className='residences-list__link' to={`/residences/${residence?.id}`}>
      <Card>
        <div className='residence-block container-fluid components'>{renderBlockFromDashboard}</div>
      </Card>
    </Link>
  ) : (
    <Card>
      <div className='residence-block container-fluid'>{renderNormalBlock}</div>
    </Card>
  );
};

export default ResidenceBlock;
