import styled from 'styled-components';
import breakpoint from 'components/styled/breakpoints/breakpoints';

const PasswordRecoveryStyled = styled.section.attrs({
  className: 'password-recovery-page'
})`
  text-align: center;
  height: 100%;

  .password-recovery-page__container {
    position: relative;
  }

  .password-recovery-page__header {
    margin-bottom: 35px;
  }

  .password-recovery-page__form {
    text-align: left;
    margin-top: 40px;
  }

  /*  @media only screen and ${breakpoint.device.lg} {
    padding-top: 60px;
  }*/
`;

export default PasswordRecoveryStyled;
PasswordRecoveryStyled.displayName = 'PasswordRecoveryStyled';
