import React from 'react';
import { useGetAllDocumentsByTypeQuery } from 'api/services/documentApi';

const useGetDocumentsByType = (type, joint_ownership) => {
  const { data, isLoading } = useGetAllDocumentsByTypeQuery(
    {
      type,
      joint_ownership,
      page: 1,
      perPage: 6000
    },
    {
      skip: !joint_ownership
    }
  );

  return [data, isLoading];
};

export default useGetDocumentsByType;
