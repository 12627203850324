import React from 'react';
import { LinkButtonStyled } from 'components/styled/buttons/ButtonStyled';
import SignatureComponent from '../signature/SignatureComponent';
import TextSignature from '../signature/TextSignature';
import lastStepSvg from './last-step.svg';
import { useSigneForEventMutation } from 'api/services/meetingEventParticipantApi';
import { toast } from 'react-toastify';
import SwitchWithText from 'components/forms/SwitchWithText';

const LastStep = ({ event, setStep, isOnGoing = false }) => {
  const [sigChoice, setSigChoice] = React.useState(false);
  const [signature, setSignature] = React.useState(null);
  const [signeForEvent] = useSigneForEventMutation();

  React.useEffect(() => {
    if (signature) {
      signeForEvent({
        id: event?.currentParticipant?.id,
        confirmationSignature: signature,
        confirmationDate: new Date()
      });
      toast('Merci pour votre signature', {
        type: 'success'
      });
    }
  }, [signature]);

  return (
    <>
      {!signature && !isOnGoing && (
        <>
          <div className='bg-gris'>
            <header className='signature-header'>
              <h1 className='d-none d-lg-block'>{}</h1>
              <h2 className='secondaryText'>Veuillez confirmer vos votes avec votre signature.</h2>
            </header>
            <div className='col-6 offset-3'>
              <SwitchWithText alterText='Saisir' firstText='Dessiner' onHandleChange={setSigChoice} />
            </div>
          </div>

          {!sigChoice ? (
            <SignatureComponent getSignature={setSignature} showExit setStep={setStep} />
          ) : (
            <TextSignature getSignature={setSignature} showExit setStep={setStep} />
          )}
        </>
      )}

      {(signature !== null || isOnGoing) && (
        <div className='g-2 mt-5 mb-5 ms-4 text-center '>
          {/*
          Ce texte est pour le temps reel
          <h1 className='text-center col'>L’assemblée est terminée !</h1>*/}
          <h1 className='text-center'>Merci pour votre participation.</h1>
          <br />
          <img src={lastStepSvg} alt='' className='mt-5 mb-5' />

          <LinkButtonStyled to='/meetings' className={'size-auto-lg'}>
            Retourner au dashboard
          </LinkButtonStyled>
        </div>
      )}
    </>
  );
};

export default LastStep;
