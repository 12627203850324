import { createApi } from '@reduxjs/toolkit/query/react';
import axios from 'axios';

const axiosBaseQuery =
  ({ baseUrl } = { baseUrl: '' }) =>
  async ({ url, method, params, data, header, responseType = 'json' }) => {
    const token = window.sessionStorage.getItem('token');
    //  TODO: verify if midelware catch all error
    // if (!token && !['/authentication_token', '/login_check'].includes(url)) {
    //   window.location.href = '/login';
    // }
    let contentType = 'application/json';

    if (method === 'PATCH') {
      contentType = 'application/merge-patch+json';
    }

    const accept = header ? 'application/ld+json' : 'application/json';

    try {
      const result = await axios({
        url: baseUrl + url,
        method,
        data,
        params: params,
        responseType,
        headers:
          token && token !== 'null'
            ? {
                Authorization: `Bearer ${token}`,
                'Content-Type': contentType,
                Accept: accept
              }
            : {
                'Content-Type': 'application/json',
                Accept: 'application/json'
              }
      });
      return { data: result.data };
    } catch (axiosError) {
      let err = axiosError;
      //  TODO: verify if midelware catch all error
      // if (err.response?.status === 401) {
      //   if (!['/forgot-password', '/login_check'].includes(url)) {
      //     sessionStorage.removeItem('auth');
      //     sessionStorage.removeItem('token');
      //     if (window.location.pathname !== 'login') {
      //       window.location.href = '/login';
      //     }
      //   }
      // }

      return {
        error: { status: err.response?.status, data: err.response?.data }
      };
    }
  };

export const apiCreator = ({ reducerPath, endPointsConfigFn, ...otherArgs }) =>
  createApi({
    reducerPath,
    baseQuery: axiosBaseQuery({
      baseUrl: process.env.REACT_APP_API_BASEURL
    }),
    refetchOnMountOrArgChange: true,
    keepUnusedDataFor: 0,
    endpoints: endPointsConfigFn,
    ...otherArgs
  });
