import styled from 'styled-components';
import { CSS_GUIDE } from 'components/styled/constants';

const CircleNumber = styled.span.attrs({
  className: 'circle-number'
})`
  ${({ small }) => {
    if (small) {
      return `
        width: 30px;
        height: 30px;
        font-size: 1rem;
      `;
    }
    return `
      width: 38px;
      height: 38px;
      font-size: 1.25rem;
    `;
  }};
  background-color: ${CSS_GUIDE.colors.orange.hex};
  color: #ffffff;
  border-radius: 50px;
  font-weight: 700;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`;

export default CircleNumber;
CircleNumber.displayName = 'CircleNumber';
