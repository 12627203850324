import styled from 'styled-components';
import breakpoint from '../breakpoints/breakpoints';

const GenericRowWrapper = styled.div.attrs({
  className: 'generic-row-wrapper'
})`
  padding: 0 20px;
  /*  @media only screen and ${breakpoint.device.lg} {
    padding-right: calc(60px - 0.5rem); // compensates bootstrap row extra padding
  }

  @media only screen and ${breakpoint.device.xl} {
    max-width: 1140px;
  }

  @media only screen and ${breakpoint.device.xxl} {
    max-width: 1320px;
  }*/
`;

export default GenericRowWrapper;
GenericRowWrapper.displayName = 'GenericRowWrapper';
