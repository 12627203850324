import React, { useEffect, useState } from 'react';
import useModal from 'hooks/useModal';
import { BlockSection } from 'components/styled/blocks/BlockSection';
import ContactBlock from './components/contact/ContactBlock';
import ModalConvocation from 'pages/dashboard/components/meetingItems/ModalConvocation';
import { useGetAllMeetingsQuery, useGetPendingConfirmationsQuery } from 'api/services/meetingApi';
import GenericPageWrapper from 'components/styled/wrappers/GenericPageWrapper';
import AppLogo from '../../components/commun/AppLogo';
import MainSubContainer from '../../components/styled/wrappers/MainSubContainer';
import { useGetResidencesQuery } from 'api/services/jointOwnershipApi';
import MeetingItems from 'pages/dashboard/components/meetingItems';
import ResidencesGroup from 'pages/dashboard/components/residence/ResidencesGroup';
import { HeadlineStyled1 } from 'components/styled/headings/HeadlineStyled';
import AppIcon from 'components/icons/AppIcon';
import { useAuthContext } from 'auth/ProvideAuth';
import { Loader } from 'components/loader';

const Dashboard = () => {
  const { openModal, ...modalProps } = useModal(true);

  const {
    data: confirmations,
    isLoading: isLoadingConfirmations,
    error: errorsConfirmations
  } = useGetPendingConfirmationsQuery();

  const { data: meetings, isLoading: isLoadingMeetings, error: errorsMeetings } = useGetAllMeetingsQuery();

  const { data: residences, isLoading: isLoadingResidences, error: errorsResidences } = useGetResidencesQuery();

  const { user } = useAuthContext();

  useEffect(() => {
    if (confirmations && confirmations.length) {
      openModal();
    }
  }, [confirmations]);

  useEffect(() => {
    if (!isLoadingMeetings && meetings?.length) {
      sessionStorage.setItem(
        'jointOwnerships',
        JSON.stringify(
          meetings.map((meeting) => ({
            joint_ownership_id: meeting.joint_ownership_id,
            joint_ownership_name: meeting.joint_ownership_name,
            user_ids: meeting.user_ids
          }))
        )
      );
    }
  }, [meetings, isLoadingMeetings]);

  if (isLoadingConfirmations) {
    return <Loader width={200} />;
  }

  return (
    <GenericPageWrapper className='dashboard-page row'>
      <nav className='dashboard-logo-zone generic-page-wrapper__view-zone d-flex justify-content-center d-lg-none align-items-center'>
        <AppIcon classes={'dashboard-logo'} name={'logo-borderless'} />
        {/*<AppLogo classes={'dashboard-logo'} borderLess />*/}
      </nav>

      <BlockSection className='top-section'>
        {/*<MainSubContainer className='row'>*/}
        <MainSubContainer>
          <div className='container-lg block-section__container'>
            <div className='welcome-user-zone d-none d-lg-flex align-items-center'>
              <div className='welcome-user__smiley-face'>
                <AppIcon name='smiley-face' />
              </div>
              {user && (
                <HeadlineStyled1 $weight='medium' className='welcome-user-title'>
                  Bonjour {user.firstName} {user.lastName}
                </HeadlineStyled1>
              )}
            </div>
            {/*// todo Adpat when data will be available*/}
            {/*            <ListHeadStyled className='container-fluid d-none d-lg-block'>
              <div className='row text-center'>
                <div className='col-3 '>Propriétés</div>
                <div className='col-3'>Adresses</div>
                <div className='col-2'>Unités</div>
                <div className='col-4'>Depuis le</div>
              </div>
            </ListHeadStyled>*/}
            {residences?.length > 0 && (
              <>
                <h3 className='block-section__title'>Copropriétés</h3>
                <ResidencesGroup
                  isDashboard
                  residences={residences}
                  isLoading={isLoadingResidences}
                  error={errorsResidences}
                />
              </>
            )}
          </div>
        </MainSubContainer>
      </BlockSection>

      <BlockSection className='mid-section'>
        <div className='row'>
          <div className='container-lg block-section__container'>
            {!isLoadingMeetings && !errorsMeetings && <MeetingItems meetings={meetings} />}
          </div>
        </div>
      </BlockSection>

      <BlockSection className='low-section block-section block-section-contacts'>
        <MainSubContainer className='row'>
          <div className='container-lg block-section__container'>
            <h3 className='block-section__title'>Mes contacts</h3>
            <p>Besoin d'aide ? Vous avez une question ? </p>
            <ul className='contacts-list'>
              {[
                { name: 'Syndic', phone: '+352 26 68 45 01', email: 'info@aea.lu' },
                { name: 'Conseil syndical', phone: null, email: 'info@aea.lu' }
              ].map((contact) => (
                <li key={contact.name}>
                  <ContactBlock name={contact.name} phone={contact.phone} email={contact.email} />
                </li>
              ))}
            </ul>
          </div>
        </MainSubContainer>
      </BlockSection>

      {confirmations && confirmations.length > 0 && (
        <ModalConvocation confirmations={confirmations} modalProps={modalProps} />
      )}
    </GenericPageWrapper>
  );
};

export default Dashboard;
