import React from 'react';
import VoteMaster from '../components/votes';
import ProxiedParticipants from '../components/votes/ProxiedParticipants';
import DocumentDisplay from 'components/commun/documentDisplay';
import { Button } from 'components/styled/buttons/ButtonStyled';
import { toast } from 'react-toastify';
import { useSendVoteDataMutation, useUpdateVoteDataMutation } from 'api/services/meetingVoteApi';
import ResultLine from 'pages/vote/components/results/resultLine';

const SimpleVote = ({ item, event, setStep, itemVote, step, permissionToVote, result }) => {
  // API
  const [sendVoteData, { isSuccess }] = useSendVoteDataMutation();
  const [updateVoteData, { isSuccess: isSuccessUpdate }] = useUpdateVoteDataMutation();

  // Helper constants
  const currentId = event?.currentParticipant?.id;
  const currentParticipant = {
    id: currentId,
    name: ''
  };

  const simpleOptions = [
    { id: 1, label: 'Pour' },
    { id: 0, label: 'Contre' },
    { id: 2, label: 'Abstention' }
  ];

  const initialVoteState = [
    {
      id: null,
      master: true,
      item: `/api/v1/meeting_items/${item.id}`,
      participant: `/api/v1/meeting_event_participants/${currentId}`,
      participantId: currentId,
      initVote: null
    }
  ];

  const mandants = event?.currentParticipant?.proxiedParticipants || null;

  // States
  const [voteOptions, setVoteOptions] = React.useState(simpleOptions);
  const [votes, setVotes] = React.useState(initialVoteState);
  const [sameForOthers, setSameForOthers] = React.useState(true);

  React.useEffect(() => {
    const convertor = { false: 0, true: 1, null: 2 };

    if (itemVote?.length > 0) {
      const tmpVote = itemVote?.map((v) => ({
        id: v.id,
        master: v.participant.id === currentParticipant.id,
        item: `/api/v1/meeting_items/${item.id}`,
        participant: `/api/v1/meeting_event_participants/${v.participant.id}`,
        participantId: v.participant.id,
        initVote: v?.itemOption?.id ? v?.itemOption?.id : convertor[JSON.stringify(v?.vote)]
      }));

      if (!tmpVote.find((v) => v.participantId === currentParticipant.id)) {
        tmpVote.push(initialVoteState[0]);
      }
      setVotes([...tmpVote]);

      const defaultVote = tmpVote.find((v) => v.master)?.initVote;
      setSameForOthers(tmpVote.every((v) => v.initVote === defaultVote));
    }

    if (item?.options?.length > 0) {
      setVoteOptions([...item.options, { id: 0, label: 'Contre' }, { id: 2, label: 'Abstention' }]);
    } else {
      setVoteOptions(simpleOptions);
    }
  }, [itemVote, step, item]);

  React.useEffect(() => {
    if (isSuccess || isSuccessUpdate) {
      toast('merci pour votre vote', {
        type: 'success'
      });
      setVotes(null);
      setStep((prevStep) => prevStep + 1);
    }
  }, [isSuccess, isSuccessUpdate]);

  // API call
  const voteProcess = async () => {
    if (!permissionToVote) {
      toast("Vous n'avez pas le droit de vote", {
        type: 'error'
      });
      return;
    }

    const finalVote = [...votes, ...masterVote(votes, sameForOthers, mandants, item.id)];

    const validation = finalVote.every((vote) => vote.initVote !== '' && vote.initVote !== null);

    if (!validation) {
      toast('Merci de vérifier vos votes', {
        type: 'error'
      });
      return;
    }

    for (let vote of finalVote) {
      const voteObj = {
        itemOption: vote.initVote > 3 ? `/api/v1/meeting_item_options/${vote.initVote}` : null,
        vote: convertVote(vote.initVote)
      };
      if (vote?.id) {
        await updateVoteData({
          id: vote.id,
          data: {
            item: vote.item,
            participant: vote.participant,
            ...voteObj
          }
        });
      } else {
        await sendVoteData({
          data: {
            item: vote.item,
            participant: vote.participant,
            ...voteObj
          }
        });
      }
    }
  };

  const getResultValue = (key) => {
    if (item.options?.length > 0) {
      if (typeof key === 'number') {
        return parseFloat(result?.result.find((r) => parseInt(r.supplierId) === key)?.supplierVote ?? 0).toFixed(3);
      } else {
        return result?.result
          ?.filter((r) => !r.supplierId)
          ?.map((res) => res[key])
          ?.reduce((acc, current) => parseFloat(acc + current), 0)
          .toFixed(3);
      }
    }

    return parseFloat(result?.result[0][key] ?? 0).toFixed(3);
  };

  if (!item?.voteStart) {
    return (
      <>
        {item?.options?.length === 0 && (
          <ResultLine
            label={'Pour'}
            resultValue={getResultValue('forVote')}
            resultFor={'forVote'}
            total={result?.totalThousand}
          />
        )}

        {item?.options?.length > 0 &&
          item?.options.map((option) => (
            <ResultLine
              key={option.id}
              label={option?.supplier?.company?.name}
              resultValue={getResultValue(option.id)}
              resultFor={'supplier'}
              total={result?.totalThousand}
            />
          ))}

        <ResultLine
          label={'Abstention'}
          resultValue={getResultValue('abstention')}
          resultFor={'abstention'}
          total={result?.totalThousand}
        />

        <ResultLine
          label={'Contre'}
          resultValue={getResultValue('against')}
          resultFor={'against'}
          total={result?.totalThousand}
        />
      </>
    );
  }

  return (
    <div className={'simple-vote mobile-padding-space'}>
      {/*TODO needs more precision */}
      {item?.options.length > 0 && item?.documents && item?.documents?.length > 0 && (
        <div className='boxShadow p-3'>
          <>
            <>
              <h1>Pièces-jointes</h1>
              <div className='blockFiles'>
                {item.documents.map((doc) => (
                  <DocumentDisplay key={doc.id} id={doc.id} name={doc.name} classes='mt-2 mb-2 col-md-6' />
                ))}
              </div>
            </>

            <h1> Prestataire(s) proposé(s)</h1>
            <div className='blockFiles'>
              {item.options.map(
                (option) =>
                  option?.documents?.length > 0 &&
                  option.documents.map((doc) => (
                    <DocumentDisplay
                      key={doc.id}
                      id={doc.id}
                      name={option.supplier.company.name}
                      price={option.price}
                      classes='mt-2 mb-2 col-sm-3'
                    />
                  ))
              )}
            </div>
          </>
        </div>
      )}

      {votes.length > 0 && (
        <>
          <VoteMaster
            options={voteOptions}
            user={currentParticipant}
            itemId={item.id}
            key={item.id + 'master'}
            votes={votes}
            setVotes={setVotes}
          />

          {mandants?.length > 0 && (
            <ProxiedParticipants
              key={item.id + 'slave'}
              options={voteOptions}
              users={mandants}
              itemId={item.id}
              votes={votes}
              setVotes={setVotes}
              setSameForOthers={setSameForOthers}
              sameForOthers={sameForOthers}
            />
          )}
        </>
      )}

      <div className='d-flex justify-content-end'>
        <Button
          type='submit'
          className={`size-auto-lg ${!permissionToVote ? 'disabledBtn' : ''}`}
          onClick={voteProcess}
          disabled={!permissionToVote}
        >
          Voter
        </Button>
      </div>
    </div>
  );
};
export default SimpleVote;

function masterVote(votes, sameForOthers, mandants, itemId) {
  const initVote = [];
  if (sameForOthers && mandants?.length) {
    for (let mandant of mandants) {
      initVote.push({
        master: false,
        item: `/api/v1/meeting_items/${itemId}`,
        participant: `/api/v1/meeting_event_participants/${mandant.id}`,
        participantId: mandant.id,
        initVote: votes.filter((vote) => vote.master)[0]?.initVote
      });
    }
  }
  return initVote;
}

function convertVote(initVote) {
  if (initVote === 0) return false;
  if (initVote === 1) return true;
  if (initVote === 2) return null;
}

// to get options we need to patch :https://api.aea.preview.nvision.lu/api/v1/meeting_items/{id} id of item option
// with options [] => { supplier {id} and price is float !  }
// TODO: getings roles : https://api.aea.preview.nvision.lu/api/v1/meeting_event_participant_roles
