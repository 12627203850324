import React from 'react';
import ElectionVote from './ElectionVote';

const ElectionMandant = ({ mandants, roles, optionsVote, chosenOne, isDisabled = false, onVote, vote }) => {
  return (
    <>
      {mandants &&
        mandants.map((mandant) => (
          <div
            className={isDisabled === false && chosenOne === mandant.value ? '' : 'visually-hidden'}
            key={'mandant-div' + mandant.value}
          >
            <ElectionVote
              key={`mandant-${mandant.value}`}
              roles={roles}
              options={optionsVote}
              isDisabled={isDisabled}
              mandant={mandant}
              onVote={onVote}
              vote={vote}
            />
          </div>
        ))}
    </>
  );
};

export default ElectionMandant;
