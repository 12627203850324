import React, { useState } from 'react';
import LoginPageGlobal from 'components/styled/wrappers/login/LoginPageGlobal';
import PasswordRecoveryForm from './PasswordRecoveryForm';
import CodeVerification from './CodeVerification';
import PasswordRecoveryStyled from 'components/styled/wrappers/login/PasswordRecoveryStyled';

const PasswordRecovery = () => {
  const [isRecoveryMode, setIsRecoveryMode] = useState(true);
  const [userEmail, setUserEmail] = useState('');

  const gotToCodeVerification = (email) => {
    setUserEmail(email);
    setIsRecoveryMode(false);
  };

  const renderPassRecovery = (
    <div className='container password-recovery-page__container'>
      <header className='password-recovery-page__header password-header'>
        <h2>Mot de passe oublié ?</h2>
        <p>
          Entrez votre adresse e-mail ci-dessous pour recevoir les instructions de réinitialisation du mot de passe.
        </p>
      </header>
      <PasswordRecoveryForm gotToCodeVerification={gotToCodeVerification} />
    </div>
  );

  return (
    <LoginPageGlobal classes='login-page-wrapper--pass-process'>
      <PasswordRecoveryStyled>
        {isRecoveryMode ? renderPassRecovery : <CodeVerification userEmail={userEmail} />}
      </PasswordRecoveryStyled>
    </LoginPageGlobal>
  );
};

export default PasswordRecovery;
