export const CSS_GUIDE = {
  colors: {
    primary: {
      hex: '#006083',
      rgb: '0, 96, 131',
      rgba: 'rgba(0, 96, 131, 0.05)'
    },
    secondary: {
      hex: '#42AECC',
      rgb: '66, 174, 204'
    },
    links: '#3FA2F7',
    warning: '#FFC400',
    error: '#DF3030',
    success: '#6FCF97',
    voteNo: '#EB5757',
    voteAbstention: '#525252',
    black: '#000000',
    blackRgba: 'rgba(0, 0, 0, 0.85)',
    darkGrey: '#333333', // main color for text
    darkGrey2: '#525252',
    midGrey: '#666666',
    midGrey2: {
      hex: '#959595',
      rgb: '149,149,149'
    },
    midGrey3: '#C4C4C4',
    lightGrey: '#D4D4D4',
    bgGrey: '#EAEAEA', // mostly for input borders
    bgGrey2: '#F6F6F6',
    bgGrey3: '#FAFAFA',
    white: {
      hex: '#FFFFFF',
      rgb: '255, 255, 255'
    },
    orange: {
      hex: '#FB6854',
      rgb: '251, 104, 84'
    }
  },
  backgrounds: {
    primary: '#FAFAFA',
    secondary: '#f1f6f8',
    tertiary: '#E8F2F2'
  },
  borderRadius: '15px',
  space: {
    vertical: {
      input: 20
    }
  },
  boxShadow: {
    green: '0px 4px 50px rgba(111, 207, 151, 0.4)',
    red: '0px 4px 50px rgba(235, 87, 87, 0.4)',
    dark: '0px 4px 50px rgba(0, 0, 0, 0.25)',
    light: '0px 4px 50px rgba(0, 0, 0, 0.05)'
  }
};
