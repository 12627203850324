import React from 'react';
import { useAuthContext } from 'auth/ProvideAuth';

export default function Logout() {
  const { signOut } = useAuthContext();
  React.useEffect(() => {
    signOut();
  }, []);
  signOut();
  return null;
}
