import { useState } from 'react';

export default function useProvideAuth() {
  const [user, setUser] = useState(sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user')) : null);

  const [authToken, setAuthToken] = useState(sessionStorage.getItem('token'));

  const setTokens = (token) => {
    sessionStorage.setItem('token', token);
    setAuthToken(token);
  };

  const setUserInfos = (data) => {
    // TODO: update to session Storage for security
    const userObj = { firstName: data.firstName, lastName: data.lastName, id: data.id };
    setUser(userObj);
    sessionStorage.setItem('user', JSON.stringify(userObj));
  };

  const getUser = () => {
    const userData = sessionStorage.getItem('user');
    return JSON.parse(userData);
  };

  const signIn = ({ data, token }) => {
    setUserInfos(data);
    setTokens(token);
  };

  const signOut = () => {
    setUser(null);
    setTokens(null);
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('jointOwnerships');
  };

  // Return the user object and auth methods
  return {
    user,
    signIn,
    signOut,
    authToken,
    setAuthToken: setTokens,
    getUser
  };
}
