import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import rtkQueryErrorLogger from 'middleware/error.middleware';
import { reducers } from './reducers';
import { authApi } from './auth/authApi';
import { meetingApi } from './services/meetingApi';
import { meetingEventParticipantApi } from './services/meetingEventParticipantApi';
import { meetingStaticDataApi } from './services/meetingStaticDataApi';
import { meetingVoteApi } from './services/meetingVoteApi';
import { documentApi } from './services/documentApi';

export const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authApi.middleware,
      meetingApi.middleware,
      meetingEventParticipantApi.middleware,
      meetingStaticDataApi.middleware,
      meetingVoteApi.middleware,
      documentApi.middleware,
      rtkQueryErrorLogger
    )
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);
