import React from 'react';
import { useGetMeetingByIdQuery, useGetMeetingItemByIdQuery } from 'api/services/meetingApi';
import RenderType from './voteTypes';
import { useParams } from 'react-router';
import { Loader } from 'components/loader';
import MainSubContainer from 'components/styled/wrappers/MainSubContainer';
import PageTitle from 'components/commun/PageTitle';
import { IconLabelButtonStyled } from 'components/styled/buttons/ButtonStyled';
import { isAfter } from 'date-fns';

const VotePage = () => {
  const { id: eventId } = useParams();
  const [currentItemId, setCurrentItemId] = React.useState(null);
  const [step, setSteps] = React.useState(null);
  const [reRender, setReRender] = React.useState(true);
  const { data, isLoading, error } = useGetMeetingByIdQuery(eventId);
  const { data: itemData, isLoading: isLoadingItem } = useGetMeetingItemByIdQuery(currentItemId, {
    skip: !currentItemId
  });

  React.useEffect(() => {
    if (!isLoading) {
      if (step <= data?.items?.length) setCurrentItemId(data?.items?.find((item) => item.position === step)?.id);
    }
  }, [isLoading, step]);

  const goNextItem = () => {
    setReRender(false);
    setSteps(step + 1);
    setTimeout(() => {
      setReRender(true);
    }, 1000);
  };

  const goPreviousItem = () => {
    setReRender(false);
    setSteps(step - 1);
    setTimeout(() => {
      setReRender(true);
    }, 1000);
  };

  React.useEffect(() => {
    if (!isLoading) {
      if (!data?.timeStart || data?.timeEnd) {
        setSteps(3);
      } else {
        setSteps(1);
      }
    }
  }, [isLoading]);

  if (isLoading || isLoadingItem || !step || !reRender) {
    return <Loader />;
  }

  if (error) {
    return <h1>Vous n'avez pas le droit à acceder à cette AG</h1>;
  }

  return (
    <MainSubContainer className={`row vote-step-${step}`}>
      {/* TODO:  is hidden from mobile juste to design it */}
      <div className='row d-none d-lg-block g-0'>
        <PageTitle jointOwnership={data?.jointOwnership} subText={'Vote'} />
      </div>
      {/* end TODO */}
      {/*<div className='row'>*/}
      <div className='g-0'>
        <div className='render-type-zone'>
          {reRender && (
            <RenderType
              step={step}
              item={itemData}
              event={data?.event}
              totalSteps={data.items.length}
              setStep={setSteps}
              secretaryNbr={data?.jointOwnership?.secretaryNbr}
              proxiedParticipants={[
                ...data?.event?.currentParticipant?.proxiedParticipants?.map((p) => p.id),
                data?.event?.currentParticipant?.id
              ]}
              currentParticipant={data?.event?.currentParticipant?.id}
              isOnGoing={!!data?.timeStart}
              itIsEnd={!!data?.timeEnd}
            />
          )}
        </div>
      </div>

      <div className='prevNextStep'>
        {data.items.length >= step && step > 1 && (data?.timeStart || step > 3) && !data.timeEnd && (
          <IconLabelButtonStyled
            iconName={'arrow-vector'}
            dynamicText={'Précédent'}
            className='prevNextStep-btn prev-step-btn mt-5 mb-5'
            handleOnClick={goPreviousItem}
            iconDirection={'left'}
            hasBgColor
            withTransition
          />
        )}

        {data.items.length >= step && (
          <IconLabelButtonStyled
            iconName={'arrow-vector'}
            dynamicText={'Suivant'}
            className='prevNextStep-btn next-step-btn mt-5 mb-5'
            handleOnClick={goNextItem}
            hasBgColor
            reversed
            withTransition
          />
        )}
      </div>
    </MainSubContainer>
  );
};

export default VotePage;
