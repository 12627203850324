import { isRejectedWithValue } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

/**
 * Log a warning and show a toast!
 */
const rtkQueryErrorLogger = (api) => (next) => (action) => {
  if (action?.error) {
    console.warn('action', action);
    console.log('action data', action.data);
    console.log('action error', action.error);
  }
  // if (isRejectedWithValue(action)) {
  //   console.warn('We got a rejected action!', api);
  //   const {
  //     payload: { data }
  //   } = action;
  //   if (!data) {
  //     return next(action);
  //   }
  //   console.warn('action', action);
  //   console.log('action data', data);
  //   console.log('action error', action.error);

  //   switch (data?.code) {
  //     case 400:
  //       toast.error(data?.detail, {
  //         position: 'bottom-center',
  //         closeOnClick: false
  //       });
  //       // toast.warn({ title: data?.title, message: data?.detail });
  //       console.log('Something went wrong');
  //       break;

  //     case 401:
  //       sessionStorage.removeItem('token');
  //       // window.location.href = 'login';
  //       break;
  //     case 403:
  //       toast.error(data?.detail, {
  //         position: 'bottom-center',
  //         closeOnClick: false
  //       });
  //       return next(action);
  //       break;
  //     case data?.status >= 500:
  //       console.log('Server unreachable');
  //       toast.error(data?.detail, {
  //         position: 'bottom-center',
  //         closeOnClick: false
  //       });
  //       break;

  //     default:
  //       return () => {};
  //   }
  // }

  return next(action);
};

export default rtkQueryErrorLogger;
