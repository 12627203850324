import styled from 'styled-components';
import { CSS_GUIDE } from 'components/styled/constants';
import { setCheckThinIcon, setCrossIcon } from 'components/styled/utils/styled.helpers';

export const Fill = styled.div`
  /* Create a custom radio button */
  border: 1px solid rgba(${CSS_GUIDE.colors.primary.rgb}, 1);
  background: #ffffff;
  height: 30px;
  width: 30px;
  border-radius: 100%;
  position: absolute;
  left: 10px;
  top: 50%;
  margin-top: -15px;
  transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out;
  pointer-events: none;

  ${({ reverse }) => {
    if (reverse) {
      return `
        right: 0;
        left: auto;
      `;
    }
  }}
`;

export const Input = styled.input`
  /* Hide the browser's default radio button */
  opacity: 0;
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  /* When the radio button is checked, handle the background */

  &:checked {
    & ~ ${Fill} {
      border: 1px solid rgba(${CSS_GUIDE.colors.primary.rgb}, 0);
      background: ${CSS_GUIDE.colors.success} ${setCheckThinIcon('FFFFFF')} no-repeat center;

      ${({ value, isSelected, isBlock, isSupplier }) => {
        if ((value === 1 && isSelected) || (isSupplier && isSelected) || (value === 'presentDistance' && isSelected)) {
          return `
            background: ${CSS_GUIDE.colors.white.hex} ${setCheckThinIcon()} no-repeat center;
          `;
        }
        if ((value === 0 && isSelected) || (value === 'false' && isSelected)) {
          return `
            background: ${CSS_GUIDE.colors.white.hex} ${setCrossIcon()} no-repeat 3px 3px;
          `;
        }
        if (value === 2 && isBlock) {
          return `
            background: ${CSS_GUIDE.colors.white.hex} ${setCheckThinIcon('525252')} no-repeat center;
          `;
        }
      }}
    }
  }
`;
