import React from 'react';
import AppLogo from 'components/commun/AppLogo';
import { NavLinkToStyled } from 'components/styled/buttons/CustomLinkToStyled';
import NavLinks from 'components/layout/nav/navLinks';

export default function DesktopNav() {
  return (
    <nav className='desktop-nav'>
      <AppLogo />

      <NavLinks device={'desktop'} />
    </nav>
  );
}
