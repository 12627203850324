import React from 'react';
import styled from 'styled-components';
import { Link, NavLink } from 'react-router-dom';
import AppIcon from '../../icons/AppIcon';
import { CSS_GUIDE } from 'components/styled/constants';

const CustomLinkTo = ({ className, to, iconName, isNavLink, children, exact, click = null }) =>
  isNavLink ? (
    <NavLink exact={exact} to={to} className={className} onClick={click}>
      <AppIcon name={iconName} />
      <span>{children}</span>
    </NavLink>
  ) : (
    <Link to={to} className={className}>
      <AppIcon name={iconName} />
      <span>{children}</span>
    </Link>
  );

const CustomLinkToStyled = styled(CustomLinkTo).attrs({
  className: 'custom-link-to-styled'
})`
  display: inline-block;
  width: 30px;
  position: absolute;
  left: 30px;
  top: 35px;
  ${({ iconName }) => {
    if (iconName === 'arrow') return 'transform: rotate(90deg);';
  }}
  ${({ iconName }) => {
    if (iconName === 'cross-field') {
      return `
        left: auto;
        right: 25px;
      `;
    }
  }}
`;

export const NavLinkToStyled = styled(CustomLinkTo).attrs({
  className: 'nav-to-link-styled'
})`
  box-shadow: 5px 10px 45px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  color: #ffffff;
  display: flex;
  align-items: center;
  min-height: 60px;
  padding-left: 20px;
  font-size: 1rem;
  background-color: rgba(${CSS_GUIDE.colors.secondary.rgb}, 0);
  transition: background-color 0.2s ease-in-out;
  flex-wrap: wrap;
  overflow: hidden;

  &.active {
    background-color: rgba(${CSS_GUIDE.colors.secondary.rgb}, 1);
  }

  &:hover {
    background-color: rgba(${CSS_GUIDE.colors.secondary.rgb}, 1);
    color: #ffffff;
  }

  .aea-svg {
    margin-right: 10px;
    fill: #ffffff;
  }
`;

export default CustomLinkToStyled;
CustomLinkToStyled.displayName = 'CustomLinkToStyled';
