import styled from 'styled-components';
import img from 'assets/images/building.jpg';

const Sidebar = styled.aside`
  background: url(${img}) no-repeat right bottom;
  position: relative;
  max-width: 240px;
  background-size: cover;

  .desktop-nav {
    position: relative;
    text-align: center;
    padding-top: 40px;
  }

  .desktop-nav__links {
    margin-top: 50px;
    padding: 0 20px;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(173.16deg, #42aecc -33.81%, #006083 65.4%);
    opacity: 0.95;
  }

  .desktop-nav__link-item + .desktop-nav__link-item {
    margin-top: 20px;
  }
`;

export default Sidebar;
