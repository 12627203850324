import styled from 'styled-components';
import { CSS_GUIDE } from 'components/styled/constants';
import breakpoint from 'components/styled/breakpoints/breakpoints';

const residence = styled.div`
  color: ${CSS_GUIDE.colors.primary.hex};
  display: inline-flex;
  align-items: center;
  font-size: 14px;

  svg {
    fill: ${CSS_GUIDE.colors.primary.hex};
    width: 49px;
    height: 49px;
  }
`;

export const ResidenceRefStyled = styled(residence)`
  padding-bottom: 20px;
  @media only screen and ${breakpoint.device.lg} {
    flex-direction: column;
    padding-bottom: 0;
  }
`;

export const ResidenceLocationStyled = styled(residence).attrs({
  className: 'residence-block__info'
})`
  align-items: center;

  p {
    margin-bottom: 0;
  }

  h4 {
    margin: 30px 0 0 0;
  }

  ${({ isDashboard }) => {
    if (isDashboard) {
      return `
      display: flex;
        flex-direction: column;
        align-items: flex-start;
        svg {
          width: 30px;
          height: 30px;
          margin-left: -8px;
          margin-right: 0
          }
      `;
    }
  }}

  @media only screen and ${breakpoint.device.lg} {
    flex-direction: column;
    margin-left: 20px;
  }
`;

ResidenceRefStyled.displayName = 'ResidenceRefStyled';
ResidenceLocationStyled.displayName = 'ResidenceLocationStyled';
