import React from 'react';
import { Link } from 'react-router-dom';

import { Election } from './Election';
import { FreeDiscussion } from './FreeDiscussion';
import SignatureType from './signatureType';
import SimpleVote from './SimpleVote';
import LastStep from '../components/lastStep';
import AppIcon from 'components/icons/AppIcon';
import VoteStepperStyled from 'components/styled/molecules/VoteStepStyled';
import MeetingItemBlock from 'pages/meetings/components/MeetingItemBlock';
import DocumentDisplay from 'components/commun/documentDisplay';
import { useGetResults, useGetVotes } from '../components/votes/hook';
import { Loader } from 'components/loader';

const RenderType = ({
  step,
  item,
  event,
  totalSteps,
  setStep,
  secretaryNbr,
  proxiedParticipants,
  currentParticipant,
  isOnGoing,
  itIsEnd
}) => {
  const [skip, setSkip] = React.useState(true);
  const [permissionToVote, setPermissionToVote] = React.useState(true);
  const [{ data: itemVote, isLoading }, setFilter] = useGetVotes(skip);
  const [{ data: itemResult, isLoadingResult }] = useGetResults(
    item?.id,
    !item || (item && item?.position === 1 && !item?.voteStart)
  );

  React.useEffect(() => {
    if (item) {
      setFilter({
        page: 1,
        perPage: 30,
        item: item?.id,
        participantIds: proxiedParticipants.join(',')
      });
      setPermissionToVote(
        (item?.shouldVote.includes(currentParticipant) ||
          proxiedParticipants.some((proxied) => item?.shouldVote.includes(proxied))) &&
          item?.voteStart &&
          !itIsEnd
      );
      setSkip(false);
    }
  }, [item]);

  const getVoteType = (voteType) => {
    switch (voteType) {
      case 0:
        return <SignatureType event={event} title={item?.translations?.fr.title} setStep={setStep} />;
      case 1:
        return (
          <Election
            item={item}
            event={event}
            setStep={setStep}
            secretaryNbr={secretaryNbr}
            itemVote={itemVote}
            permissionToVote={permissionToVote}
            result={itemResult}
          />
        );
      case 6:
        return <FreeDiscussion step={step} event={event} item={item} />;
      case 2:
      case 3:
      case 4:
      case 5:
        return (
          <SimpleVote
            step={step}
            item={item}
            key={item.id + '' + step}
            event={event}
            setStep={setStep}
            itemVote={itemVote}
            permissionToVote={permissionToVote}
            result={itemResult}
          />
        );
      default:
        return <LastStep event={event} setStep={setStep} isOnGoing={isOnGoing} />;
    }
  };

  if (isLoading || skip || isLoadingResult) {
    return <Loader width={50} />;
  }
  return (
    <section className={'render-vote-type'}>
      {step <= totalSteps && (
        <div className='bgBleuMobile pt-3'>
          <div className='vote-backTo-block'>
            <Link to='/' className='mt-5 mb-5 back-to-link back-to-link--from-meeting d-lg-none'>
              <AppIcon name='arrow' classes='rotate ms-2 me-2' /> Assemblée générale
            </Link>
          </div>

          <VoteStepperStyled stepNbr={step} totalSteps={totalSteps} className='d-lg-none mt-3' />

          <div className='vote-header'>
            {step === 1 && (
              <>
                <h1 className='d-lg-none'>{item?.translations?.fr.title}</h1>
              </>
            )}
            {step > 1 && (
              <>
                <h1 className='d-lg-none'>{item?.translations?.fr.title}</h1>
                <MeetingItemBlock mainTitle={item?.translations?.fr.title} step={step}>
                  {item?.documents?.map((doc) => (
                    <DocumentDisplay key={doc.id} id={doc.id} name={doc.name} />
                  ))}
                </MeetingItemBlock>
              </>
            )}
          </div>
        </div>
      )}
      <div>{step <= totalSteps ? getVoteType(item?.voteType) : getVoteType(null)}</div>
    </section>
  );
};

export default RenderType;
