import styled from 'styled-components';
import { CSS_GUIDE } from 'components/styled/constants';

const ErrorText = styled.div.attrs({
  className: 'error-text-styled'
})`
  color: ${CSS_GUIDE.colors.error};
  font-size: 14px;
  text-align: left;
  max-width: 360px;
  ${(props) =>
    props.withBackground
      ? `
          background: ${CSS_GUIDE.colors.error};
          color: ${CSS_GUIDE.colors.white.hex};
          border-radius: ${CSS_GUIDE.borderRadius};
          margin-top: 10px;
          display: inline-block;
          padding: 2px 10px;
          `
      : ''}
`;

export default ErrorText;
