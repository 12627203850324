import styled from 'styled-components';
import { CSS_GUIDE } from 'components/styled/constants';

const ListHeadStyled = styled.div`
  border-radius: ${CSS_GUIDE.borderRadius};
  background-color: ${CSS_GUIDE.backgrounds.tertiary};
  color: ${CSS_GUIDE.colors.primary.hex};
  font-weight: 700;
  height: 60px;
  line-height: 60px;
`;

export default ListHeadStyled;
