import styled from 'styled-components';

const GradientWrapper = styled.div.attrs({
  className: 'gradient-wrapper'
})`
  position: relative;
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(173.16deg, #42aecc -33.81%, #006083 65.4%);
    opacity: 0.95;
    z-index: -5;
  }
`;

export default GradientWrapper;
GradientWrapper.displayName = 'GradientWrapper';
