import React from 'react';
import CodeVerificationForm from 'pages/passwordRecovery/CodeVerificationForm';
import { Button } from 'components/styled/buttons/ButtonStyled';

const CodeVerification = ({ userEmail }) => {
  return (
    <section className='code-verification'>
      <div className='container'>
        <header className='code-verification__header container'>
          <h2>Code de vérification</h2>
          <p>L'e-mail a bien été envoyé!</p>
          <p>Vous avez dû recevoir un code de vérification à saisir ici pour réinitialiser votre mot de passe. </p>
        </header>

        <CodeVerificationForm userEmail={userEmail} />
      </div>

      <footer className='code-verification__footer'>
        <div className='container'>
          <p className='code-verification__footer__title'>Vous n'avez pas reçu l'e-mail?</p>
          <p>Jetez un oeil dans vos spams ou essayez à nouveau.</p>
          <Button>Renvoyer le code</Button>
        </div>
      </footer>
    </section>
  );
};

export default CodeVerification;
