export const documents = [
  {
    title: 'Contrat',
    type: '18',
    stepThree: true
  },
  {
    title: 'Facture',
    type: '1',
    stepThree: false
  },
  {
    title: 'Compromis de vente', // pas de cop
    type: '3',
    stepThree: false
  },
  {
    title: 'Acte notarié', // sans cop
    type: '4',
    stepThree: true
  },
  {
    title: 'Cadastre',
    type: '9',
    stepThree: true
  },
  {
    title: 'Procès-verbal',
    type: '11',
    stepThree: true
  },
  {
    title: 'Contrat syndic',
    type: '10',
    stepThree: false
  },

  {
    title: 'Bail',
    type: '5',
    stepThree: false
  },

  {
    title: 'Acte de vente',
    type: '6',
    stepThree: false
  },
  {
    title: 'Décompte',
    type: '8',
    stepThree: false
  },

  {
    title: 'Règlement copropriété',
    type: '12',
    stepThree: true
  },
  {
    title: 'Assurances',
    type: '13',
    stepThree: false
  },

  {
    title: 'Système technique',
    type: '16',
    stepThree: false
  },
  {
    title: 'Matériel',
    type: '17',
    stepThree: false
  },

  {
    title: 'Clef',
    type: '19',
    stepThree: false
  },
  {
    title: 'Offre',
    type: '20',
    stepThree: false
  },

  {
    title: 'Circulaire',
    type: '21',
    stepThree: false
  },
  {
    title: 'Lettre',
    type: '22',
    stepThree: false
  },

  {
    title: 'Fax',
    type: '23',
    stepThree: false
  },
  {
    title: 'Formulaire de vote',
    type: '24',
    stepThree: false
  },
  {
    title: 'Extraits bancaires',
    type: '25',
    stepThree: false
  },
  {
    title: 'Procuration',
    type: '26',
    stepThree: false
  },
  {
    title: 'Fiche technique',
    type: '27',
    stepThree: false
  },
  {
    title: 'Liste de présences',
    type: '28',
    stepThree: false
  },
  {
    title: 'Budget',
    type: '29',
    stepThree: false
  },
  {
    title: 'Fiche de salaire',
    type: '30',
    stepThree: false
  },
  {
    title: 'Carte identité/passeport',
    type: '31',
    stepThree: false
  },
  {
    title: 'État des lieux',
    type: '32',
    stepThree: false
  },
  {
    title: 'Rapport expertise',
    type: '33',
    stepThree: false
  },
  {
    title: 'Assignation',
    type: '34',
    stepThree: false
  },
  {
    title: 'Jugement',
    type: '35',
    stepThree: false
  },
  {
    title: 'Photo',
    type: '36',
    stepThree: false
  },
  {
    title: 'Rappel',
    type: '37',
    stepThree: false
  },
  {
    title: 'Mise en demeure',
    type: '38',
    stepThree: false
  },
  {
    title: 'Convocation',
    type: '39',
    stepThree: false
  },
  {
    title: 'Carte de sécurité',
    type: '40',
    stepThree: false
  },
  {
    title: 'Contrat de travail',
    type: '41',
    stepThree: false
  },
  {
    title: 'Acte communal',
    type: '42',
    stepThree: false
  },
  {
    title: 'Commande',
    type: '43',
    stepThree: false
  },
  {
    title: 'Bon de travail',
    type: '44',
    stepThree: false
  },
  {
    title: 'Fiche de travail',
    type: '45',
    stepThree: false
  },
  {
    title: 'Ordonnance de paiement',
    type: '46',
    stepThree: false
  },
  {
    title: 'Certificat syndic',
    type: '48',
    stepThree: false
  },
  {
    title: 'Certificat vente',
    type: '49',
    stepThree: false
  },
  {
    title: 'Conclusions',
    type: '50',
    stepThree: false
  },
  {
    title: 'Appel de fonds',
    type: '14',
    stepThree: true
  },

  {
    title: 'Passeport énergétique',
    type: '15',
    stepThree: true
  }
];

/*
"document_type": {
  "cadastre": "Cadastre",
    "syndic_contract": "Contrat syndic",
    "appointment_minute": "Procès-verbal",
    "joint_ownership_rules": "Règlement copropriété",
    "insurance": "Assurances",
    "fundrasing_call": "Appel de fonds",
    "notary_need": "Acte notarié",
    "energy_passport": "Passeport énergétique",
    "invoice": "Facture",
    "record": "Record",
    "sales_agreement": "Compromis de vente",
    "lease_contract": "Bail",
    "sale_deed": "Acte de vente",
    "SELLER_CHARGE_STATEMENT": "SELLER CHARGE STATEMENT",
    "CHARGE_STATEMENT": "Décompte",
    "TECHNICAL_SYSTEM": "Système technique",
    "TECHNICAL_SYSTEM_EQUIPMENT": "Matériel",
    "CONTRACT": "Contrat",
    "KEY": "Clef",
    "OFFER": "Offre",
    "CIRCULAR": "Circulaire",
    "LETTER": "Lettre",
    "FAX": "Fax",
    "VOTING_FORM": "Formulaire de vote",
    "BANK_STATEMENTS": "Extraits bancaires",
    "PROXY": "Procuration",
    "TECHNICAL_SHEET": "Fiche technique",
    "ATTENDANCE_LIST": "Liste de présences",
    "BUDGET": "Budget",
    "PAYSLIP": "Fiche de salaire",
    "ID_CARD": "Carte identité/passeport",
    "INVENTORY": "État des lieux",
    "EXPERT_REPORT": "Rapport expertise",
    "ASSIGNMENT": "Assignation",
    "JUDGMENT": "Jugement",
    "PHOTO": "Photo",
    "RECALL": "Rappel",
    "FORMAL_NOTICE": "Mise en demeure",
    "SUMMONS": "Convocation",
    "SECURITY_CARD": "Carte de sécurité",
    "EMPLOYMENT_CONTRACT": "Contrat de travail",
    "COMMUNAL_DEED": "Acte communal",
    "ORDERED": "Commande",
    "WORK_ORDER": "Bon de travail",
    "WORKSHEET": "Fiche de travail",
    "PAYMENT_ORDER": "Ordonnance de paiement",
    "TYPE_CERTIFICATE_SYNDICATE": "Certificat syndic",
    "TYPE_CERTIFICATE_SALES": "Certificat vente",
    "TYPE_CONCLUSIONS": "Conclusions"
}*/
