export const AUTH_TOKEN = '/authentication_token';
export const USER_RESET = '/users/reset';
export const USER_RESET_CHECK = '/users/reset-check';
export const USER_RESET_CONFIRM = '/users/reset-confirm';

// MEETING
export const MEETINGS = '/meetings';
export const PENDING_CONFIRMATIONS = '/meetings/pending_confirmations';
export const MEETING_BY_ID = (X) => `/meetings/${X}`;

// MEETING_ITEM
export const MEETING_ITEM_BY_ID = (X) => `/meeting_items/${X}`;
export const MEETING_ITEM_COMMENT = `/meeting_item_comments`;
export const MEETING_ITEM_COMMENT_BY_ID = (X) => `/meeting_item_comments/${X}`;

// MEETING EVENT Participant
export const MEETING_EVENT_PARTICIPANTS = (X) => `/meeting_event_participants/${X}`;

// MEETING EVENT Data  : Roles ...
export const MEETING_EVENT_PARTICIPANTS_ROLE = `/meeting_event_participant_roles`;

// MEETING_VOTE
export const MEETING_VOTE = '/meeting_votes';
export const MEETING_VOTE_BY_ID = (X) => `/meeting_votes/${X}`;
export const MEETING_VOTE_ELECTION = '/meeting_votes/election';
export const MEETING_VOTE_RESULTS = (X) => `/meeting_votes/result/${X}`; // id = meetingItemId

// DOCUMENTS
export const DOCUMENTS = '/documents';
export const DOCUMENT_X = (x) => `/documents/${x}/download`;

// JOINT_OWNERSHIPS
export const JOINT_OWNERSHIPS = '/joint_ownerships';
export const JOINT_OWNERSHIP_BY_ID = (X) => `/joint_ownerships/${X}`;
export const JOINT_OWNERSHIP_BUNDLES_TENANTS = (X) => `/joint_ownerships/${X}/bundles_tenants`;

//convocations
export const MEETING_INVITATIONS_BY_ID = (X) => `/meeting_notifications/${X}`;
