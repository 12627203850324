import styled from 'styled-components';
import breakpoint from 'components/styled/breakpoints/breakpoints';
import { CSS_GUIDE } from 'components/styled/constants';

const ResidencesGroupWrapper = styled.div.attrs({
  className: 'residence-blocks-wrapper'
})`
  .residences-list {
    display: flex;
    overflow-x: auto;
    margin-bottom: 0;
    padding: 0 15px 20px 0.75rem;
  }

  .residences-list__item .styled-card {
    margin: 0;
    min-height: 160px;
    display: flex;
    width: 100%;
    align-items: center;
    padding: 15px 0;
  }

  .residences-list__item {
    margin: 0;
    min-height: 160px;
    display: flex;
    align-items: center;
    padding: 15px 0;
  }

  .residences-list__item + .residences-list__item {
    margin-left: 15px;
  }

  .residences-list__link {
    display: block;
    width: 100%;
    text-decoration: none;
    color: ${CSS_GUIDE.colors.darkGrey2};
    line-height: inherit;
  }

  @media only screen and ${breakpoint.device.lg} {
    .residences-list {
      flex-direction: column;
      padding: 0;
    }

    .residences-list.block-section-stretcher {
      padding-left: 30px;
      padding-right: 30px;
      padding-top: 20px;
      margin-left: -30px;
      margin-right: -30px;
    }

    .residences-list__item .styled-card {
      padding: 15px;
      min-height: 140px;
    }

    .residences-list__item {
      margin: 0 0 20px;
      padding-top: 0;
    }

    .residences-list__item + .residences-list__item {
      margin-left: 0;
    }

    .residence-block__col {
      position: relative;
    }

    .residence-block__col + .residence-block__col {
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 5px;
        left: 0;
        width: 1px;
        height: 50px;
        background-color: ${CSS_GUIDE.colors.primary.hex};
      }
    }

    #svg-arrow {
      position: absolute;
      right: 10px;
      padding: 0;
      width: 25px;
      height: 25px;
      top: 50%;
      margin-top: -15px;
      transform: rotate(-90deg);
      transition: 0.2s all ease-out;
    }

    .residences-list__link:hover #svg-arrow {
      right: 5px;
    }
  }
`;

export default ResidencesGroupWrapper;
ResidencesGroupWrapper.displayName = 'ResidencesGroupWrapper';
