import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Card, { CardInner } from 'components/styled/wrappers/Card';
import { HeadlineStyled3 } from 'components/styled/headings/HeadlineStyled';
import { Button, ButtonLight } from 'components/styled/buttons/ButtonStyled';
import TextArea from 'components/styled/forms/TextArea';
import { toast } from 'react-toastify';
import {
  useAddMeetingItemCommentMutation,
  useDeleteMeetingItemCommentMutation,
  useGetMeetingItemCommentsQuery,
  useUpdateMeetingItemCommentMutation
} from 'api/services/meetingApi';
import styled from 'styled-components';
import breakpoint from 'components/styled/breakpoints/breakpoints';
import AppIcon from 'components/icons/AppIcon';
import { Loader } from 'components/loader';

const MeetingItemComment = ({ item, participantId }) => {
  const {
    data: commentData,
    isLoading,
    refetch
  } = useGetMeetingItemCommentsQuery({
    item: item.id,
    participant: participantId
  });

  const { handleSubmit, control, reset, watch } = useForm({
    defaultValues: {
      comment: '',
      id: null
    }
  });

  const [addMeetingItemComment, { data: successAddData, isSuccess: isSuccessAdd, isError: isErrorAdd }] =
    useAddMeetingItemCommentMutation();
  const [deleteMeetingItemComment, { isSuccess: isSuccessDelete, isError: isErrorDelete }] =
    useDeleteMeetingItemCommentMutation();
  const [updateMeetingItemComment, { data: updatedComment, isSuccess: isSuccessUpdate, isError: isErrorUpdate }] =
    useUpdateMeetingItemCommentMutation();

  useEffect(() => {
    if (isSuccessAdd) {
      toast('Votre commentaire a bien été ajouté', {
        type: 'success'
      });
      reset({
        id: successAddData.id,
        comment: successAddData.comment
      });
    }
    if (isSuccessUpdate) {
      toast('Votre commentaire a bien été mis a jour', {
        type: 'success'
      });
      reset({
        id: updatedComment.id,
        comment: updatedComment.comment
      });
    }
  }, [isSuccessAdd, successAddData, isSuccessUpdate, updatedComment, reset]);

  useEffect(() => {
    if (isSuccessDelete) {
      reset({
        id: null,
        comment: ''
      });
      toast('Votre commentaire a bien été supprimé', {
        type: 'success'
      });
    }
  }, [isSuccessDelete]);

  useEffect(() => {
    if (isErrorUpdate || isErrorAdd) {
      toast("Votre commentaire n'a pu être envoyé", {
        type: 'error'
      });
    }
  }, [isErrorUpdate, isErrorAdd, isErrorDelete]);

  useEffect(() => {
    if (!isLoading) {
      reset({
        id: commentData[0]?.id ?? null,
        comment: commentData[0]?.comment ?? ''
      });
    }
  }, [isLoading, commentData, reset]);

  const sendData = async (d) => {
    if (d?.id) {
      await updateMeetingItemComment({
        id: d.id,
        data: {
          comment: d.comment
        }
      });
    } else {
      await addMeetingItemComment({
        participant: `/api/v1/meeting_event_participants/${participantId}`,
        item: `/api/v1/meeting_items/${item.id}`,
        comment: d.comment
      });
    }
    refetch();
  };

  const deleteComment = async (id) => {
    await deleteMeetingItemComment(id);
    refetch();
  };

  if (isLoading) {
    return <Loader width={50} />;
  }

  const commentId = watch('id');

  return (
    <Card>
      <CardInner>
        <MeetingItemCommentStyled>
          <HeadlineStyled3 $weight={'bold'}>Commentaire</HeadlineStyled3>
          <div className={'d-flex justify-content-end'}>
            <button type={'button'} disabled={!commentId} onClick={() => (commentId ? deleteComment(commentId) : null)}>
              <AppIcon name={'trash'} />
            </button>
          </div>

          <form className={'comment-form'} onSubmit={handleSubmit(sendData)}>
            <Controller
              control={control}
              name={'comment'}
              rules={{
                required: 'Merci de remplir ce champs',
                minLength: {
                  value: 1,
                  message: 'Saisir au moins un caractère'
                },
                maxLength: {
                  value: 500,
                  message: 'La limite de 500 caractères est atteinte'
                }
              }}
              render={({ field: { onChange, onBlur, value, name }, fieldState: { error } }) => (
                <TextArea
                  onChange={onChange}
                  onBlur={onBlur}
                  entity={'item-comment'}
                  error={error}
                  placeholder={'Ajouter un commentaire, proposer un autre prestataire...'}
                  value={value}
                />
              )}
            />

            <footer className={'d-flex comment-form__footer'}>
              <Button type={'submit'} className={'comment-submit-btn comment-form__btn'}>
                Ajouter un commentaire
              </Button>
            </footer>
          </form>
        </MeetingItemCommentStyled>
      </CardInner>
    </Card>
  );
};

export default MeetingItemComment;

const MeetingItemCommentStyled = styled('div').attrs({
  className: 'meeting-item-component-styled'
})`
  h3 {
    margin-bottom: 30px;
  }

  .comment-form {
    display: flex;
    flex-direction: column;
    justify-content: end;
  }

  .comment-form__footer {
    margin-top: 40px;
    flex-direction: column;
  }

  .comment-form__btn + .comment-form__btn {
    margin-top: 20px;
  }

  .comment-form__btn {
    width: auto;
  }

  @media only screen and ${breakpoint.device.lg} {
    .comment-form__footer {
      flex-direction: row;
      justify-content: end;
    }

    .comment-form__btn + .comment-form__btn {
      margin-top: 0;
      margin-left: 15px;
    }
  }
`;
