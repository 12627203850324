import React from 'react';
import { ToastContainer } from 'react-toastify';
import { ProvideAuth } from 'auth/ProvideAuth';
import ToasterProvider from 'context/toaster/toaster.context';
import AppLayout from 'components/layout';
import 'react-toastify/dist/ReactToastify.min.css';
import 'assets/styles/app-toaster.css';
import 'assets/styles/app.css';

function App() {
  return (
    <ProvideAuth>
      <ToasterProvider>
        <AppLayout />
        <ToastContainer
          draggable
          autoClose={3000}
          newestOnTop
          pauseOnFocusLoss={false}
          pauseOnHover
          position='bottom-center'
          className='app-toaster-container'
          theme={'colored'}
        />
      </ToasterProvider>
    </ProvideAuth>
  );
}

export default App;
