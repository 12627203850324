import styled from 'styled-components';
import breakpoint from 'components/styled/breakpoints/breakpoints';

const MeetingItemsWrapper = styled.div.attrs({
  className: 'meeting-items-wrapper'
})`
  @media only screen and ${breakpoint.device.lg} {
    overflow: auto;

    .meetings-list {
      display: flex;
    }

    .meetings-list__item {
      flex: 1 0 auto;
      min-width: 430px;
    }

    .meetings-list__item + .meetings-list__item {
      margin-left: 30px;
    }
  }

  @media only screen and ${breakpoint.device.xl} {
    .meetings-list__item {
      flex: inherit;
    }
  }
`;

export default MeetingItemsWrapper;
MeetingItemsWrapper.displayName = 'MeetingItemsWrapper';
