import styled from 'styled-components';
import breakpoint from '../breakpoints/breakpoints';

const MainSubContainer = styled.section.attrs({
  className: 'main-sub-container'
})`
  @media only screen and ${breakpoint.device.lg} {
    padding-right: calc(60px - 0.5rem); // compensates bootstrap row extra padding
    width: 100%;
  }

  @media only screen and ${breakpoint.device.xl} {
    max-width: 1140px;
  }

  @media only screen and ${breakpoint.device.xxl} {
    max-width: 1320px;
  }
`;

export default MainSubContainer;
