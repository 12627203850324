import styled from 'styled-components';
import { CSS_GUIDE } from '../constants';
import breakpoint from '../breakpoints/breakpoints';

export const BlockSection = styled.section.attrs({
  className: 'block-section'
})`
  padding-top: 20px;
  padding-bottom: 20px;

  .block-section__title,
  &.block-section-contacts {
    color: ${CSS_GUIDE.colors.primary.hex};
  }

  .block-section__title {
    margin-bottom: 20px;
  }

  &.block-section-contacts .block-section__title {
    margin-bottom: 10px;
  }

  &.top-section {
    margin-top: -160px;
    padding: 0;

    .block-section__title {
      color: #ffffff;
      padding-left: 0.75rem;
    }

    .container-lg {
      padding: 0;
    }
  }

  @media only screen and ${breakpoint.device.lg} {
    &,
    &.top-section {
      padding: 30px calc(60px - 0.75rem) 30px; // we subtract bootstrap's padding
    }

    /*    &.top-section {
      padding-top: 0;
    }*/
    &.top-section .block-section__title {
      color: ${CSS_GUIDE.colors.primary.hex};
      padding-left: 0;
    }

    &.top-section {
      margin-top: 0;
    }

    .meetings-list {
      display: flex;
    }

    &.mid-section {
      background-color: ${CSS_GUIDE.backgrounds.secondary};
    }

    // helps giving space to let the box-shadow of children visible
    .block-section__list {
      padding-left: 30px;
      padding-right: 30px;
      padding-top: 20px;
      margin-left: -30px;
      margin-right: -30px;
    }

    .welcome-user-title {
      margin-bottom: 0;
      max-width: calc(100% - 250px);
    }

    .welcome-user__smiley-face {
      margin-right: 25px;
      position: relative;
      padding-left: 15px;
      flex-shrink: 0;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        display: block;
        height: 43px;
        width: 43px;
        background-color: ${CSS_GUIDE.colors.orange.hex};
        z-index: 0;
        border-radius: 50px;
      }
    }

    .welcome-user-zone {
      margin-bottom: 62px;

      #svg-smiley-face {
        position: relative;
        max-width: 51px;
        max-height: 52px;
        fill: ${CSS_GUIDE.colors.primary.hex};
      }
    }

    .page-top-zone {
      padding-left: 40px;
      color: ${CSS_GUIDE.colors.primary.hex};
      margin-bottom: 40px;

      .custom-link-to-styled {
        //position: static;
        margin-right: 25px;
      }
    }
  }
`;
