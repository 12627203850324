export const longTextDemo = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc elementum porttitor mollis. Vestibulum et lectus quis arcu blandit molestie. Sed mattis sollicitudin nunc, ac facilisis lectus vehicula vitae. Morbi augue est, vestibulum nec nisl quis, tempus pulvinar libero. Praesent sodales ex ut tortor lobortis lacinia. Pellentesque id mi felis. Praesent lacus sapien, fringilla et nisi at, imperdiet porttitor erat. Sed venenatis mi nunc, in malesuada urna laoreet vel. Etiam volutpat nisl nisl, sed consectetur purus blandit vitae. Fusce volutpat, quam gravida volutpat efficitur, mauris diam dapibus eros, vel condimentum sapien elit nec quam. Nullam pretium fringilla consequat. Duis a blandit lorem.\n" +
  "\n" +
  "Ut vitae ex rhoncus, vehicula eros at, lacinia erat. Mauris imperdiet justo a ante consectetur, aliquam hendrerit leo maximus. Vivamus orci tortor, porttitor id massa malesuada, convallis ultrices tortor. Ut lacinia nec nunc non cursus. Curabitur nec posuere ex. Etiam tincidunt nibh non massa accumsan laoreet. Nam nec lectus eu dui porta rhoncus et a ante. Nunc urna tellus, volutpat sed feugiat id, volutpat a nibh. Nam ac facilisis nunc, at tincidunt velit. Quisque bibendum lorem eget est accumsan, sed mollis diam luctus.\n" +
  "\n" +
  "Nunc a ipsum ligula. Integer imperdiet, erat eget consectetur tincidunt, odio massa imperdiet nulla, bibendum placerat erat eros et eros. Etiam ornare vestibulum risus. Sed sit amet cursus orci. Suspendisse potenti. Mauris ac cursus orci. Donec ligula enim, tristique eu feugiat sit amet, laoreet quis lorem. Curabitur congue vitae ligula ac porttitor. Nullam nunc justo, pulvinar ut massa ac, bibendum placerat lacus. Vivamus vitae porta justo. Nulla tincidunt est sit amet diam commodo, quis molestie lectus aliquam. Nulla accumsan fringilla metus ac ultricies. Pellentesque tristique enim eget diam fermentum, vitae accumsan lectus varius. In hac habitasse platea dictumst. Praesent eget viverra augue, ac tincidunt nisi.\n" +
  "\n" +
  "Duis lacinia sit amet nisi sit amet vulputate. Sed imperdiet turpis ut eros aliquet, sodales cursus neque faucibus. Vivamus at tempus odio. Nunc ut nulla a arcu imperdiet iaculis at ac tellus. Vestibulum malesuada ex ac lectus porttitor, a fringilla neque blandit. Proin nibh odio, maximus non purus vitae, rhoncus euismod risus. Nullam iaculis vitae tortor in finibus. Cras finibus et sem ac sagittis. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nulla sollicitudin venenatis odio, ac laoreet lectus commodo ac. Maecenas accumsan interdum congue.\n" +
  "\n" +
  "Mauris ullamcorper vel urna id cursus. Aliquam dictum ligula ac justo pellentesque dignissim vitae ut neque. Mauris dictum tincidunt enim a iaculis. Interdum et malesuada fames ac ante ipsum primis in faucibus. Donec suscipit vel eros dapibus facilisis. Morbi tempor id leo eget mattis. Nulla at auctor nibh, vitae suscipit nulla. Phasellus sodales libero quis nisi bibendum, bibendum finibus leo tristique. Mauris fringilla, augue a congue auctor, justo velit mollis ipsum, ac lobortis nulla orci ac nisl. Quisque scelerisque, dui pretium lobortis gravida, augue felis commodo augue, nec porttitor libero eros ac tellus. Fusce hendrerit aliquet justo, id efficitur sapien viverra eget. Proin ut congue neque. Fusce nunc nulla, accumsan faucibus dignissim sed, elementum a augue. Vestibulum volutpat, justo ut interdum malesuada, nisl ex molestie velit, nec viverra nibh justo vitae massa.\n" +
  "\n" +
  "Integer vel malesuada diam. Vivamus sed dolor ac urna tempus aliquam. Cras blandit ultricies vehicula. Proin dui risus, convallis eget lectus vitae, mollis bibendum ante. Morbi placerat vestibulum magna, ac finibus purus faucibus non. Sed interdum lectus vel ipsum sollicitudin, nec aliquet mi ornare. Fusce aliquet elit vel velit egestas, sit amet bibendum leo sollicitudin. Vestibulum accumsan ut ante lacinia dignissim. Maecenas elementum est sed aliquet molestie. Phasellus sollicitudin magna elementum suscipit interdum. Praesent vel quam vulputate, finibus ipsum sed, tristique dolor.\n" +
  "\n" +
  "Praesent a nibh et quam tristique cursus eget eu sem. Integer congue, arcu non ullamcorper maximus, turpis ex venenatis orci, in condimentum mi ex dapibus lectus. Mauris non lacus sit amet dui pretium malesuada vitae nec sem. Phasellus et sodales sapien, at placerat lectus. Maecenas a cursus massa. Mauris iaculis sit amet sapien vitae ultrices. Aenean aliquam convallis augue, a venenatis magna scelerisque convallis. Proin ut lorem vitae diam pharetra laoreet eu in nibh. Nullam eros metus, vestibulum quis justo vitae, aliquam aliquam felis."