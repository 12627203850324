import styled from 'styled-components';
import { CSS_GUIDE } from '../constants';

const BurgerStyled = styled.button`
  width: 182px;
  height: 107px;
  border-radius: 100px;
  background-color: ${CSS_GUIDE.colors.orange.hex};
  position: absolute;
  z-index: 15;
  left: ${({ showNav }) => (showNav ? '-129px' : '-99px')};
  top: 20px;
  transition: left 0.2s ease-in-out;
  border: 0;
  background-position: 80% center;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg width='26' height='18' viewBox='0 0 26 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M24.1089 7.84033H0.980035C0.438762 7.84033 0 8.27909 0 8.82037C0 9.36164 0.438762 9.8004 0.980035 9.8004H24.1089C24.6501 9.8004 25.0889 9.36164 25.0889 8.82037C25.0889 8.27909 24.6501 7.84033 24.1089 7.84033Z' fill='white'/%3E%3Cpath d='M17.2206 0H0.700025C0.313401 0 0 0.438762 0 0.980035C0 1.52131 0.313401 1.96007 0.700025 1.96007H17.2206C17.6072 1.96007 17.9206 1.52131 17.9206 0.980035C17.9206 0.438762 17.6072 0 17.2206 0Z' fill='white'/%3E%3Cpath d='M24.1089 15.6805H0.980035C0.438762 15.6805 0 16.1193 0 16.6606C0 17.2018 0.438762 17.6406 0.980035 17.6406H24.1089C24.6501 17.6406 25.0889 17.2018 25.0889 16.6606C25.0889 16.1193 24.6501 15.6805 24.1089 15.6805Z' fill='white'/%3E%3C/svg%3E%0A");
`;

export default BurgerStyled;
BurgerStyled.displayName = 'BurgerStyled';
