import React from 'react';
import Select from 'react-select';

import Card, { CardInner } from 'components/styled/wrappers/Card';
import SwitchCheckbox from 'components/forms/SwitchCheckbox';
import { useGetMeetingRolesQuery } from 'api/services/meetingStaticDataApi';
import ElectionVote from '../components/election/ElectionVote';
import ElectionMandant from '../components/election/ElectionMandant';
import { useUpdateVoteDataMutation, useVoteElectionMutation } from 'api/services/meetingVoteApi';
import { Button } from 'components/styled/buttons/ButtonStyled';
import { toast } from 'react-toastify';
import { Loader } from 'components/loader';
import { HeadlineStyled3 } from 'components/styled/headings/HeadlineStyled';

export const Election = ({ item, event, setStep, secretaryNbr, itemVote, permissionToVote, result }) => {
  const getParticipant = (id) => {
    if (event?.participants) {
      if (event.participants.find((p) => p.syndic)) return 'Syndic';
      return event.participants.find((p) => p.id === id)?.owner?.fullName;
    }
  };

  const [sameForOthers, setSameForOthers] = React.useState(true);
  const [chosenOne, setChosenOne] = React.useState(null);

  const participants = transformOptions(event?.participants?.filter((p) => p.isPrimaryContact || p.syndic));
  const mandants = transformOptions(event?.currentParticipant?.proxiedParticipants);
  const currentId = event?.currentParticipant?.id;
  const currentParticipant = {
    value: currentId,
    label: ''
  };
  const [vote, setVote] = React.useState([
    {
      itemId: item.id,
      participantId: currentId,
      master: true,
      roles: []
    }
  ]);

  const [updateVoteData, { isSuccess: isUpdated }] = useUpdateVoteDataMutation();
  const { data: roles, isLoading } = useGetMeetingRolesQuery();
  const [voteElection, { isSuccess }] = useVoteElectionMutation();

  React.useEffect(() => {
    if (itemVote && itemVote?.length) {
      const newVote = [];
      itemVote.forEach((apiVoteItem) => {
        if (!newVote.some((v) => v?.participantId === apiVoteItem?.participant?.id)) {
          newVote.push({
            itemId: apiVoteItem.item.id,
            participantId: apiVoteItem.participant.id,
            master: currentId === apiVoteItem.participant.id,
            roles: [
              ...itemVote
                ?.filter((it) => it.participant.id === apiVoteItem.participant.id)
                .map((obj) => ({
                  id: obj.id,
                  roleId: parseInt(obj?.role?.split('/').pop()),
                  roleFor: obj?.roleFor?.id
                }))
            ]
          });
        }
      });

      setVote([...newVote]);
    }
  }, [itemVote]);

  React.useEffect(() => {
    if (isSuccess || isUpdated) {
      toast('Vote vote est bien enregistrer', {
        type: 'success'
      });
      setStep((prevStep) => prevStep + 1);
    }
  }, [isSuccess, isUpdated]);

  const filteredRolesList = roles?.slice(0, 2 + (secretaryNbr ?? 0));

  const handleChange = () => {
    setSameForOthers(!sameForOthers);
    if (sameForOthers && mandants.length) {
      const initVote = masterVote(vote, sameForOthers, mandants, item.id);
      setVote([...vote, ...initVote]);
    } else {
      setVote([...vote.filter((v) => v.participantId === currentId)]);
    }
  };

  const voteProcess = async () => {
    if (!permissionToVote) {
      toast("Vous n'avez pas le droit de vote", {
        type: 'error'
      });
      return;
    }
    const initVote = masterVote(vote, sameForOthers, mandants, item.id);
    if (!validateVote([...vote, ...initVote])) {
      toast('Merci de choisir ', {
        type: 'error'
      });
      return;
    }
    if (vote[0]?.roles[0]?.id) {
      vote.forEach((v) => {
        v.roles.map(async (voteRole) => {
          await updateVoteData({
            id: voteRole.id,
            data: {
              id: voteRole.id,
              item: `/api/v1/meeting_items/${v.itemId}`,
              participant: `/api/v1/meeting_event_participants/${v.participantId}`,
              role: voteRole.roleId,
              roleFor: {
                id: voteRole.roleFor
              }
            }
          });
        });
      });
    } else {
      await voteElection({ data: [...vote, ...initVote] });
    }
    setVote([]);
  };

  if (isLoading) {
    return <Loader />;
  }

  if (!item?.voteStart) {
    return (
      <>
        <h1>Résultat de vote</h1>

        {filteredRolesList.map((role) => {
          const participantName = getParticipant(
            result?.result.find((res) => parseInt(res.roleId) === role.id)?.roleFor
          );
          return (
            <Card key={role.id}>
              {role.name} : {participantName}
            </Card>
          );
        })}
      </>
    );
  }
  return (
    <div className={'election-page mobile-padding-space'}>
      <HeadlineStyled3 $weight='bold' className='d-lg-none'>
        Mon vote
      </HeadlineStyled3>
      <Card>
        <CardInner>
          <ElectionVote
            roles={filteredRolesList}
            options={participants}
            onVote={setVote}
            vote={vote}
            isDisabled={false}
            mandant={currentParticipant}
          />
        </CardInner>
      </Card>

      {mandants?.length > 0 && (
        <>
          <h1 className='mb-3'>Mes procurations</h1>
          <SwitchCheckbox
            text='Appliquer mon vote aux mandants'
            toggled={sameForOthers}
            id={'mandants'}
            onChange={handleChange}
          />
          <div className='mt-3 mb-3'>
            <h1 className='mb-3'>Vote pour</h1>
            <div className='col-lg-6'>
              <Select
                options={mandants}
                isSearchable
                isClearable
                onChange={(data) => setChosenOne(data?.value || null)}
                isDisabled={sameForOthers}
              />
            </div>
          </div>
          {chosenOne && (
            <Card>
              <div className='mt-3 mb-3'>
                <ElectionMandant
                  mandants={mandants}
                  optionsVote={participants}
                  chosenOne={chosenOne}
                  isDisabled={sameForOthers}
                  roles={filteredRolesList}
                  onVote={setVote}
                  vote={vote}
                />
              </div>
            </Card>
          )}
        </>
      )}

      <div className='d-flex justify-content-end'>
        <Button
          type='submit'
          className={`size-auto-lg ${!permissionToVote ? 'disabledBtn' : ''}`}
          onClick={voteProcess}
          disabled={!permissionToVote}
        >
          Voter
        </Button>
      </div>
    </div>
  );
};

function transformOptions(options) {
  if (!options) return null;
  return [...options].reverse().map((option) => {
    return {
      value: option.id,
      label: !option.syndic ? option.owner?.fullName + ' ' + (option?.present ? '(présent)' : '') : 'A&A',
      syndic: option.syndic,
      present: option?.present
    };
  });
}

function validateVote(votes) {
  let valide = true;

  for (const vote of votes) {
    if (!valide) break;

    if (!vote.roles || vote.roles.length === 0) {
      valide = false;

      break;
    }

    valide = vote.roles.every((role) => role.roleFor !== null && role.roleFor !== undefined);

    if (!valide) return false;
  }

  return valide;
}

function masterVote(vote, sameForOthers, mandants, itemId) {
  const initVote = [];
  if (sameForOthers && mandants?.length) {
    for (let mandant of mandants) {
      initVote.push({
        itemId,
        participantId: mandant.value,
        roles: vote[0].roles
      });
    }
  }
  return initVote;
}
