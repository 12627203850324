import React, { forwardRef } from 'react';
import InputRadioLabelStyled from 'components/styled/forms/radioButton/InputRadioLabelStyled';
import { Fill, Input } from 'components/styled/forms/radioButton/InputRadioStyled';
import { PillInfo } from 'components/styled/pills/Pill';

const RadioButtonComponent = forwardRef(
  (
    {
      onChange = () => null,
      value,
      isSelected,
      labelText,
      pillInfoText,
      name,
      isBlock,
      classes = '',
      shadow,
      reverse,
      checked,
      callback,
      isSupplier
    },
    ref
  ) => {
    return (
      <InputRadioLabelStyled
        className={`root-radio-label ${classes}`}
        isBlock={isBlock}
        shadow={shadow}
        reverse={reverse}
        value={value}
        isSelected={isSelected}
        isSupplier={isSupplier}
      >
        <span>{labelText}</span>
        {pillInfoText && <PillInfo className={'input-pill-info'}>{pillInfoText}</PillInfo>}
        <Input
          type='radio'
          onChange={(event) => {
            onChange(event);
            if (callback) {
              callback();
            }
          }}
          name={name}
          value={value}
          checked={checked}
          aria-checked={checked}
          ref={ref}
          isSelected={isSelected}
          isBlock={isBlock}
          isSupplier={isSupplier}
        />
        <Fill className='radio-fill' reverse={reverse} />
      </InputRadioLabelStyled>
    );
  }
);

export default RadioButtonComponent;
RadioButtonComponent.displayName = 'RadioButtonComponent';
