import React from 'react';
import styled from 'styled-components';
import { CSS_GUIDE } from 'components/styled/constants';

const TextArea = ({ value, count = 0, entity = 'form', placeholder, maxLength = 500, onChange, error, onBlur }) => {
  return (
    <TextAreaStyled error={error}>
      <textarea
        className={`${entity}-textarea ${error ? 'error' : ''}`}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />

      <span className={'text-count'}>{value?.length} / 500</span>
      <p className={'error-message'}>{error?.message}</p>
    </TextAreaStyled>
  );
};

export default TextArea;

// /*//display: ${(props) => (props?.errors[props?.fieldName] ? 'block' : 'none')};*/

const TextAreaStyled = styled('div').attrs({
  className: 'textarea-styled'
})`
  position: relative;

  .error-message {
    display: ${(props) => (props?.error ? 'block' : 'none')};
    position: absolute;
    bottom: -40px;
    left: 10px;
    font-size: 0.875rem;
    color: ${CSS_GUIDE.colors.error};
  }

  .text-count {
    position: absolute;
    bottom: 0;
    right: 10px;
    font-size: 0.75rem;
    color: ${CSS_GUIDE.colors.midGrey};
  }

  textarea {
    width: 100%;
    border: 1px solid ${CSS_GUIDE.colors.bgGrey};
    border-radius: ${CSS_GUIDE.borderRadius};
    min-height: 100px;
    padding: 20px 90px 20px 20px;
    display: flex;
    align-items: center;
    outline: none;

    &.error {
      border-color: ${CSS_GUIDE.colors.error};

      &::placeholder {
        color: ${CSS_GUIDE.colors.error};
      }
    }

    &::placeholder {
      display: block;
      //position: relative;
      top: 30px;
    }
  }
`;

TextAreaStyled.displayName = 'TextAreaStyled';
