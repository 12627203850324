import React from 'react';
import { useGetAllMeetingsQuery } from 'api/services/meetingApi';
import MainSubContainer from 'components/styled/wrappers/MainSubContainer';
import styled from 'styled-components';
import { HeadlineStyled1 } from 'components/styled/headings/HeadlineStyled';
import MeetingItemComponent from 'pages/dashboard/components/meetingItems/MeetingItemComponent';
import { isAfter, isSameDay } from 'date-fns';
import { Loader } from 'components/loader';
import breakpoint from 'components/styled/breakpoints/breakpoints';

const MeetingsWelcome = () => {
  const { data: meetings, isLoading: isLoadingMeetings, error: errorsMeetings } = useGetAllMeetingsQuery();

  if (isLoadingMeetings) {
    return <Loader />;
  }

  return (
    <MainSubContainer>
      <MeetingsPageStyled className='meetings-page--styled'>
        <HeadlineStyled1>Assemblées Générales</HeadlineStyled1>

        <div className='container'>
          {meetings?.length > 0 ? (
            <div className='row'>
              {meetings
                .filter(
                  (meeting) =>
                    meeting.status_id === 3 &&
                    (isAfter(new Date(meeting?.date), new Date()) || isSameDay(new Date(meeting?.date), new Date()))
                )
                .map((meeting) => {
                  return (
                    <div key={meeting.id} className={'col-lg-6'}>
                      <MeetingItemComponent meeting={meeting} buttonLabel={'Voir détail'} />
                    </div>
                  );
                })}
            </div>
          ) : (
            <p>Vous n'avez pas d'Assemblée générale en cours</p>
          )}
        </div>
      </MeetingsPageStyled>
    </MainSubContainer>
  );
};

export default MeetingsWelcome;

const MeetingsPageStyled = styled.section`
  padding-top: 40px;

  .headline-1 {
    margin-bottom: 40px;
    text-align: right;
    padding: 0 30px;
    font-size: 1.3rem;
  }

  .meeting-item-styled {
    max-width: 100%;
  }

  @media only screen and ${breakpoint.device.lg} {
    .headline-1 {
      text-align: left;
      font-size: 2.125rem;
      padding: 0;
    }
  }
`;
