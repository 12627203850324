import React from 'react';
import { SwitchButton, SwitchButtonCheckbox, SwitchButtonLabel } from 'components/styled/forms/toggleSwitch/SwitchText';

const SwitchWithText = ({ firstText, alterText, onHandleChange = () => {} }) => {
  const handleOnChange = (event) => {
    onHandleChange(event.target.checked);
  };
  return (
    <SwitchButton alterText={alterText} className='switch-with-text'>
      <SwitchButtonCheckbox type='checkbox' onChange={handleOnChange} />

      <SwitchButtonLabel>
        <span>{firstText}</span>
      </SwitchButtonLabel>
    </SwitchButton>
  );
};

export default SwitchWithText;
