import styled from 'styled-components';
import breakpoint from 'components/styled/breakpoints/breakpoints';
import { CSS_GUIDE } from 'components/styled/constants';
import GradientWrapper from 'components/styled/wrappers/GradientWrapper';
import img from 'assets/images/building.jpg';

const LoginPageWrapper = styled(GradientWrapper).attrs({
  className: 'login-page-wrapper'
})`
  display: flex;
  justify-content: center;
  overflow-x: hidden;
  height: 100vh;
  //padding-bottom: 21px; // this is to prevent scroll because of the ::after

  .login-page__large-part {
    display: flex;
    flex: 1;
    justify-content: center;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &.login-page-wrapper--login-init .login-page__large-part {
    padding: 0 15px;
  }

  &.login-page-wrapper--pass-process .login-page__large-part {
    padding: 0;
  }

  &.login-page-wrapper--login-init .login-page__header-logo {
    position: absolute;
    left: 50%;
    margin-left: -52px;
    top: -60px;
  }

  &.login-page-wrapper--pass-process .login-page__header-logo {
    display: none;
  }

  &.login-page-wrapper--pass-process .login-page__card {
    padding-left: 0;
    padding-right: 0;
    height: 100%;
  }

  .login-form legend {
    float: none;
    font-weight: 700;
    color: ${CSS_GUIDE.colors.primary.hex};
    margin-bottom: 30px;
  }

  .login-form__bottom {
    margin-top: 15px;
  }

  &.login-page-wrapper--pass-process {
    background: url(${img}) no-repeat right bottom;
    background-size: cover;
    color: #FFFFFF;

    &:before {
      z-index: 0;
    }
  }

  @media only screen and ${breakpoint.device.lg} {
    padding: 0;
    justify-content: space-between;

    &.login-page-wrapper--pass-process {
      background: none;
      color: ${CSS_GUIDE.colors.primary.hex};

      .login-page__large-part {
        padding: 0 15px;
      }

      .login-page__card {
        padding: 90px 15px 20px;
        height: 650px;
      }

      &:before {
        z-index: -5;
      }
    }

    .login-page__header-logo {
      display: none;
    }

    .login-page__large-part {
      flex: 1;
      justify-content: center;
      align-items: center;
    }

    .login-form legend {
      margin-bottom: 80px;
    }

    .pass-wrapper {
      margin-top: 40px;
    }

    .login-form__submit {
      margin-top: 160px;
    }
  }
`;

export default LoginPageWrapper;
LoginPageWrapper.displayName = 'LoginPageWrapper';
