export const getFieldStatus = (fieldName, errors = {}, dirtyFields = {} /*touchedFields = {}*/) => {
  let fieldStatus = '';
  if (errors[fieldName]) {
    fieldStatus = 'error';
  }
  if (
    dirtyFields[fieldName] &&
    !errors[fieldName]
    // || (touchedFields[fieldName] && !errors[fieldName])
  ) {
    fieldStatus = 'valid';
  }
  return fieldStatus;
};
