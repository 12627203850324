import React from 'react';
import sprite from './icons.svg';
import './app-icons.css';

export default function AppIcon({ name, classes = '', onClick = () => {} }) {
  return (
    <svg id={`svg-${name}`} className={`aea-svg ${classes}`} onClick={onClick}>
      <use href={sprite + '#icon-' + name} />
    </svg>
  );
}
