import styled from 'styled-components';
import { CSS_GUIDE } from 'components/styled/constants';

export const InputRadioLabelStyled = styled.label`
  display: flex;
  position: relative;
  padding: 10px 10px 10px 55px;
  cursor: pointer;
  font-size: 18px;
  width: 100%;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .input-pill-info {
    margin-left: 20px;
  }

  ${({ reverse }) => {
    if (reverse) {
      return `
        padding: 10px 55px 10px 10px;
      `;
    }
  }}

  ${({ isBlock }) => {
    if (isBlock) {
      return `
  min-height: 60px;
  padding: 15px 15px 15px 55px;
  border-radius: ${CSS_GUIDE.borderRadius};
  box-shadow: ${CSS_GUIDE.boxShadow.light};
  background: ${CSS_GUIDE.colors.white.hex};
      `;
    }
  }}

  ${({ value, isSelected, isSupplier }) => {
    if (
      (isSupplier && isSelected) ||
      (parseInt(value) === 1 && isSelected) ||
      (value === 'presentDistance' && isSelected)
    ) {
      return `
        background: ${CSS_GUIDE.colors.success};
        box-shadow: ${CSS_GUIDE.boxShadow.green};
        color: ${CSS_GUIDE.colors.primary.hex};
        font-weight: 700;
        `;
    }
    if (parseInt(value) === 0 && isSelected) {
      return `
         background: ${CSS_GUIDE.colors.voteNo};
         box-shadow: ${CSS_GUIDE.boxShadow.red};
         color: ${CSS_GUIDE.colors.white.hex};
         font-weight: 500;
      `;
    }
    if (parseInt(value) === 2 && isSelected) {
      return `
        background: ${CSS_GUIDE.colors.voteAbstention};
        color: ${CSS_GUIDE.colors.white.hex};
        box-shadow: ${CSS_GUIDE.boxShadow.dark};
        font-weight: 700;
      `;
    }
  }}
`;

export default InputRadioLabelStyled;
InputRadioLabelStyled.displayName = 'InputRadioLabelStyled';
