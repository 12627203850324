import styled from 'styled-components';
import breakpoint from '../breakpoints/breakpoints';

const HeaderWrapper = styled.header.attrs({
  className: 'app-header-wrapper'
})`
  display: flex;
  .user-menu {
    //right: calc(60px - 1.3rem);
    right: 20px;
    top: 15px;
    position: absolute;
    z-index: 1;

    #svg-logout {
      fill: #ffffff;
      padding: 10px;
      max-width: 40px;
      max-height: 40px;
      background: #006083;
      border-radius: 50px;
      cursor: pointer;
    }
  }

    @media only screen and ${breakpoint.device.lg} {
      //padding-right: calc(60px + 1.9rem);
      padding: 40px calc(60px - 0.75rem) 30px; // we subtract bootstrap's padding
      margin-left: calc(-60px - 0.5rem);
      margin-bottom: -100px; // lifts the page up to occupy the empty space
      
      .main-sub-container {
        position: relative;
        min-height: 40px;
        padding-right: 40px;
        //padding-right: calc(60px - 1.3rem);
      }

      .user-menu {
        //right: calc(60px - 1.3rem);
        right: 40px;
        top: 0;
      }
    }

    @media only screen and ${breakpoint.device.xl} {
      .user-menu {
        right: 50px;
      }
    }
  }
`;

export default HeaderWrapper;
HeaderWrapper.displayName = 'HeaderWrapper';
