import { DOCUMENTS, DOCUMENT_X } from 'constants/api.constants';
import { apiCreator } from '../baseApi';

const endPoints = (builder) => ({
  getAllDocumentsByType: builder.query({
    query: ({ type, joint_ownership, page, perPage, method = 'GET' }) => ({
      params: { perPage, page, type, joint_ownership },
      url: DOCUMENTS,
      method
    })
  }),
  downloadDoc: builder.query({
    query: (id) => ({
      url: DOCUMENT_X(id),
      method: 'GET',
      responseType: 'arraybuffer'
    })
  })
});

export const documentApi = apiCreator({
  reducerPath: 'documentApi',
  endPointsConfigFn: endPoints
});
export const { useGetAllDocumentsByTypeQuery, useDownloadDocQuery } = documentApi;
