import { apiCreator } from 'api/baseApi';
import { JOINT_OWNERSHIP_BUNDLES_TENANTS, JOINT_OWNERSHIP_BY_ID, JOINT_OWNERSHIPS } from 'constants/api.constants';

const endPoints = (builder) => ({
  getResidences: builder.query({
    query: () => ({
      url: JOINT_OWNERSHIPS
    }),
    transformResponse: (data) => data?.results
  }),

  getResidenceById: builder.query({
    query: (id) => ({
      url: JOINT_OWNERSHIP_BY_ID(id)
    })
    // transformResponse: (data) => data?.results
  }),

  getBundlesTenants: builder.query({
    query: (id) => ({
      url: JOINT_OWNERSHIP_BUNDLES_TENANTS(id)
    })
  })
});

export const jointOwnershipApi = apiCreator({
  reducerPath: 'jointOwnershipApi',
  endPointsConfigFn: endPoints
});

export const { useGetResidencesQuery, useGetResidenceByIdQuery, useGetBundlesTenantsQuery } = jointOwnershipApi;
