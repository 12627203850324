import styled from 'styled-components';
import breakpoint from '../breakpoints/breakpoints';
import { CSS_GUIDE } from '../constants';

const AppWrapper = styled.div.attrs({
  className: 'ag-app-wrapper'
})`
  display: flex;
  flex-direction: column;
  height: 100%;

  @media only screen and ${breakpoint.device.lg} {
    flex-direction: row;
    overflow: hidden;
    background-color: ${CSS_GUIDE.backgrounds.primary};
  }
`;

export default AppWrapper;
AppWrapper.displayName = 'AppWrapper';
