import { authApi } from './auth/authApi';
import { documentApi } from './services/documentApi';
import { meetingApi } from './services/meetingApi';
import { meetingEventParticipantApi } from './services/meetingEventParticipantApi';
import { meetingStaticDataApi } from './services/meetingStaticDataApi';
import { meetingVoteApi } from './services/meetingVoteApi';
import { jointOwnershipApi } from 'api/services/jointOwnershipApi';

export const reducers = {
  [authApi.reducerPath]: authApi.reducer,
  [meetingApi.reducerPath]: meetingApi.reducer,
  [meetingEventParticipantApi.reducerPath]: meetingEventParticipantApi.reducer,
  [meetingStaticDataApi.reducerPath]: meetingStaticDataApi.reducer,
  [meetingVoteApi.reducerPath]: meetingVoteApi.reducer,
  [documentApi.reducerPath]: documentApi.reducer,
  [jointOwnershipApi.reducerPath]: jointOwnershipApi.reducer
};
