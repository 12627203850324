import React from 'react';
import ContactBlockStyled from 'components/styled/molecules/ContactBlockStyled';
import AppIcon from 'components/icons/AppIcon';
import Card from 'components/styled/wrappers/Card';

const ContactBlock = ({ name, phone, email }) => {
  return (
    <Card>
      <ContactBlockStyled>
        <div className='contact-block__left'>
          <AppIcon name='user' />
          <div className='contact-infos'>
            <p className='contact-infos__name'>{name}</p>
            {/*<span className='contact-infos__email'>info@aea.lu</span>
            <span className='contact-infos__tel'>+352 15 18 569</span>*/}
          </div>
        </div>

        <div className='contact-block__right'>
          {phone && (
            <div className='contact-infos__icon'>
              <a href={`tel: ${phone}`}>
                <AppIcon name='phone' />
              </a>
            </div>
          )}
          <div className='contact-infos__icon'>
            <a href={`mailto:${email}`}>
              <AppIcon name='mail' />
            </a>
          </div>
        </div>
      </ContactBlockStyled>
    </Card>
  );
};

export default ContactBlock;
