import React from 'react';
import MeetingItemStyled from 'components/styled/molecules/MeetingItemStyled';
import { PillInfo } from 'components/styled/pills/Pill';
import TextWithIcon from 'components/commun/TextWithIcon';
import { LinkButtonStyled } from 'components/styled/buttons/ButtonStyled';
import { isSameDay, isSameHour } from 'date-fns';
import { dateFormatter, daysHoursReminder } from 'utils/date';

const MeetingItemComponent = ({ meeting }) => {
  const isStart = isSameHour(new Date(meeting?.date), new Date());
  const isToday = isSameDay(new Date(meeting?.date), new Date()) && isStart && meeting?.status_id === 3;

  return (
    <MeetingItemStyled isOngoingStatus={isToday}>
      <header>
        <p className='mb-0 stronger-text'>{meeting?.joint_ownership_name}</p>
        <p className='mb-0 meeting-status'>
          {isToday ? meeting?.status_name.fr : daysHoursReminder(meeting?.date, 'dans')}
        </p>
      </header>
      <main className='mb-3'>
        <PillInfo className='meeting-pill'>Assemblée générale {meeting?.type_name?.fr}</PillInfo>
        {meeting?.address_fulltext !== 'inline meeting or null' && (
          <TextWithIcon iconName={'location'}>{meeting?.address_fulltext}</TextWithIcon>
        )}

        <TextWithIcon iconName={'clock'}>{isToday ? 'Maintenant' : dateFormatter(meeting?.date, 'edit')}</TextWithIcon>
      </main>
      <footer>
        <LinkButtonStyled
          to={isToday ? `/meetings/${meeting?.id}/vote` : `/meetings/${meeting?.id}/detail`}
          $positive={isToday}
        >
          {isToday ? 'Voter' : 'Voir le détail'}
        </LinkButtonStyled>
      </footer>
    </MeetingItemStyled>
  );
};

export default MeetingItemComponent;
