import styled from 'styled-components';
import { CSS_GUIDE } from 'components/styled/constants';
import breakpoint from 'components/styled/breakpoints/breakpoints';

const Card = styled.div.attrs({
  className: 'styled-card'
})`
  background: #ffffff;
  padding: 15px;
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  margin: 20px auto;
  position: relative;

  ${({ stripped }) => {
    if (stripped) {
      return `
        &:before {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 1px;
          width: 6px;
          height: 100%;
          background-color: ${CSS_GUIDE.colors.primary.hex};
          border-radius: 50px 0 0 50px;
        }`;
    }
  }}

  @media only screen and (max-width: 991.98 px) {
    &.transparent--mobile {
      background-color: transparent;
      box-shadow: none;
    }
  }
`;

export const CardInner = styled.div.attrs({
  className: 'card-inner'
})`
  padding: 15px 30px;

  @media only screen and ${breakpoint.device.lg} {
    padding: 25px 55px;
  }
`;

export default Card;
Card.displayName = 'Card';
