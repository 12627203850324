import React from 'react';
import LoginPageWrapper from './LoginPageWrapper';
import LoginVisualStyled from './LoginVisualStyled';
import AppLogo from 'components/commun/AppLogo';
import LoginPageCard from './LoginPageCard';
import CustomLinkToStyled from '../../buttons/CustomLinkToStyled';

const LoginPageGlobal = ({ children, showBackTo = true, classes = '' }) => {
  return (
    <LoginPageWrapper className={classes}>
      <LoginVisualStyled className='d-none d-xl-flex'>
        <AppLogo />
      </LoginVisualStyled>
      <main className='login-page__large-part'>
        <LoginPageCard>
          {showBackTo && <CustomLinkToStyled to='/' iconName={'arrow'} className='back-to-login d-none d-lg-inline-block' />}
          {showBackTo && <CustomLinkToStyled to='/' iconName={'cross-field'} className='back-to-login d-lg-none' />}
          <header className={'login-page__header-logo'}>
            <AppLogo />
          </header>
          {children}
        </LoginPageCard>
      </main>
    </LoginPageWrapper>
  );
};

export default LoginPageGlobal;