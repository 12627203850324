import styled from 'styled-components';
import { CSS_GUIDE } from 'components/styled/constants';

const colors = {
  primary: CSS_GUIDE.colors.primary.hex,
  secondary: CSS_GUIDE.colors.black,
  alt: CSS_GUIDE.colors.white.hex
};

const weights = {
  regular: 400,
  medium: 500,
  bold: 700
};

export const HeadlineStyled1 = styled.h1.attrs({
  className: 'headline-1'
})`
  font-size: 2.125rem; // 34px
  line-height: 2.625rem; // 42px
  color: ${({ $color = 'primary' }) => colors[$color]};
  font-weight: ${({ $weight = 'regular' }) => weights[$weight]};
`;

export const HeadlineStyled2 = styled.h2.attrs({
  className: 'headline-2'
})`
  font-size: 1.5rem; // 24px
  line-height: 2.625rem;
  color: ${({ $color = 'primary' }) => colors[$color]};
  font-weight: ${({ $weight = 'regular' }) => weights[$weight]};
`;

export const HeadlineStyled3 = styled.h3.attrs({
  className: 'headline-3'
})`
  font-size: 1.25rem; // 20px
  line-height: 1.5rem;
  color: ${({ $color = 'primary' }) => colors[$color]};
  font-weight: ${({ $weight = 'regular' }) => weights[$weight]};
`;
