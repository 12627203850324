import React from 'react';
import { CardInner } from 'components/styled/wrappers/Card';

export const FreeDiscussion = ({ item }) => {
  return (
    <div className='freeDiscussion'>
      <CardInner>
        {item.translations && (
          <ul className='free-discussion-list'>
            <li>{item?.translations?.fr?.title}</li>
            {/* {item?.translations?.fr?.description} */}
            {/* <li className='free-discussion-list__item'>
              <p>
                Mme Calzoni indique qu’une facture de Metrilux lui a peut-être été adressée deux fois et souhaite que la
                gérance vérifie ce point.{' '}
              </p>
            </li>
            <li className='free-discussion-list__item'>
              <p>De même, Mme Baptista indique avoir reçu deux factures pour sa télécommande</p>
            </li>
            <li className='free-discussion-list__item'>
              <p>L’assemblée signale également un problème d’étanchéité avec le volet de M. Torff.</p>
            </li> */}
          </ul>
        )}
      </CardInner>
    </div>
  );
};
