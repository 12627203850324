import React from 'react';
import Select from 'react-select';
import { HeadlineStyled3 } from 'components/styled/headings/HeadlineStyled';

const ElectionVote = ({ roles, options, isDisabled = false, mandant, onVote, vote }) => {
  const [defaultVote, setDefaultVote] = React.useState([]);

  const onHandleChange = (roleId, voteData) => {
    const mandantVote = vote?.find((v) => v.participantId === mandant.value);
    const otherVote = vote?.filter((v) => v.participantId !== mandant.value);

    onVote([
      ...otherVote,
      {
        ...mandantVote,
        roles: [
          ...mandantVote?.roles?.filter((role) => role.roleId !== roleId),
          {
            roleId,
            roleFor: voteData?.value || null,
            id: mandantVote?.roles?.find((role) => role.roleId === roleId)?.id ?? null
          }
        ]
      }
    ]);

    const newVote = { id: roleId, value: { ...voteData } };
    setDefaultVote([...defaultVote.filter((v) => v.id !== roleId), newVote]);
  };

  React.useEffect(() => {
    const mandantRolesVote = vote?.filter((v) => v.master)[0]?.roles;
    const mandantVotes = vote?.filter((v) => v.participantId === mandant.value)[0]?.roles;

    const loopVote = mandantVotes && mandantVotes.length > 0 ? mandantVotes : mandantRolesVote;

    if (loopVote) {
      const initialVote = loopVote?.map((role) => ({
        id: role.roleId,
        value: options?.filter((option) => option.value === role.roleFor)[0] ?? null
      }));
      setDefaultVote(initialVote);
    }
  }, [vote]);

  return (
    <div className='row'>
      <HeadlineStyled3 $weight='bold' className='d-none d-lg-block'>
        Mon vote
      </HeadlineStyled3>
      {roles?.map((role) => (
        <div
          className={isDisabled ? 'visually-hidden' : 'mt-3 mb-3 addClass col-lg-6'}
          key={`${role.id}-electionVote-${mandant.value}`}
        >
          <label>{role.name}</label>
          <Select
            value={
              defaultVote?.find((def) => {
                return def.id === role.id;
              })?.value || null
            }
            id={`${role.id}-electionVote-id-${mandant.value}`}
            options={options.filter((option) => (role.id > 2 && !option.syndic) || role.id <= 2)}
            isSearchable
            isClearable
            onChange={(args) => onHandleChange(role.id, args)}
            isDisabled={isDisabled}
            name={`${role.id}-vote-for-${mandant.value}`}
          />
        </div>
      ))}
    </div>
  );
};

export default ElectionVote;

// {
//   "item": {
//     id: itemId,
//   },
//   "role": {
//     roleid for each selection
//   },
//   "roleFor": "selected participant {id : id off chosen one}",
//   "participant": "string" uri
// }

// for each participant we send same data nbr of participant * number role
