import React from 'react';
import RadioButtonComponent from 'components/forms/RadioButtonComponent';
import Card, { CardInner } from 'components/styled/wrappers/Card';
import { HeadlineStyled3 } from 'components/styled/headings/HeadlineStyled';

const VoteMaster = ({ options, user, itemId, votes, setVotes }) => {
  const [myVote, setMyVote] = React.useState('');
  React.useEffect(() => {
    setMyVote(votes.find((vote) => vote.master)?.initVote);
  });

  const handleChange = (event) => {
    setMyVote(parseInt(event.target.value));
    setVotes((prev) => [
      ...prev.filter((vote) => !vote.master),
      {
        ...prev.find((vote) => vote.master),
        initVote: parseInt(event.target.value)
      }
    ]);
  };

  return (
    <div className='mt-3 ms-2 me-2'>
      <h1 className='mb-3 d-lg-none'>Mon Vote</h1>
      {options && (
        <Card className={'transparent transparent--mobile'}>
          <CardInner>
            <HeadlineStyled3 $weight='bold' className='mb-0 d-none d-lg-block'>
              Mon vote
            </HeadlineStyled3>
            <ul className={'row g-x-2'}>
              {options.map((option) => (
                <li key={`li-${itemId}-key-${option.id}`} className='col-lg-4'>
                  <RadioButtonComponent
                    onChange={handleChange}
                    key={`${itemId}-key-${option.id}`}
                    value={option.id}
                    labelText={option.label || option.supplier.company.name}
                    name={`${itemId}voteSystem${user.id}`}
                    classes='mt-2 mb-2'
                    isBlock
                    isSelected={option.id === myVote}
                    isSupplier={![0, 2].includes(myVote)}
                    checked={option.id === myVote}
                  />
                </li>
              ))}
            </ul>
          </CardInner>
        </Card>
      )}
    </div>
  );
};

export default VoteMaster;
