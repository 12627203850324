import React from 'react';
import { useGetMeetingVotesQuery, useGetVoteResultsQuery } from 'api/services/meetingVoteApi';

export const useGetVotes = (skip) => {
  const [filter, setFilter] = React.useState({
    page: 1,
    perPage: 30,
    item: null,
    participantIds: null
  });
  const votes = useGetMeetingVotesQuery(filter, {
    skip
  });
  return [votes, setFilter, filter];
};

export const useGetResults = (itemId, skip) => {
  const results = useGetVoteResultsQuery(itemId, {
    skip
  });
  return [results];
};
