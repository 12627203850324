import React from 'react';
import Login from 'pages/login/Login';
import PasswordRecovery from 'pages/passwordRecovery/PasswordRecovery';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import PasswordReset from 'pages/passwordRecovery/PasswordReset';

const UnauthenticatedApp = () => {
  return (
    <Router>
      <Switch>
        <Route exact path='/login' component={Login} />
        <Route exact path='/password-recovery' component={PasswordRecovery} />
        <Route exact path='/password-reset' component={PasswordReset} />
        <Route exact path='/password-reset/:userToken' component={PasswordReset} />
        <Route exact path='*'>
          <Redirect to='/login' />
        </Route>
      </Switch>
    </Router>
  );
};

export default UnauthenticatedApp;
