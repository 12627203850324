import styled from 'styled-components';
import { CSS_GUIDE } from 'components/styled/constants';

const CustomDataTable = styled.div.attrs({
  className: 'custom-table-data'
})`
  color: ${CSS_GUIDE.colors.primary.hex};

  .header-row {
    display: flex;
  }

  .header-row__item,
  .custom-table-data__type {
    font-size: 0.875rem;
  }

  .custom-table-data__column {
    flex: 1;
    text-align: center;

    &:first-child {
      text-align: left;
    }
  }

  .larger-column {
    flex: 2;
  }

  .custom-table-data__value,
  .custom-table-data__type {
    font-weight: 500;
    margin-bottom: 0;
  }

  .custom-table-data__value {
    font-size: 1rem;
    font-weight: 500;
  }

  .custom-table-data__type {
    color: ${CSS_GUIDE.colors.darkGrey2};
  }

  .custom-table-data__list-item + .custom-table-data__list-item {
    border-top: 1px solid ${CSS_GUIDE.colors.primary.hex};
    padding-top: 10px;
  }
`;

export default CustomDataTable;
CustomDataTable.displayName = 'CustomDataTable';
