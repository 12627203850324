import React from 'react';
import ModalBase from './ModalBase';

const ModalFullScreen = ({ modalProps, children, ...restProps }) => {
  const customOverrides = {
    overlay: { backgroundColor: 'rgba(0, 0, 0, 1)' },
    content: {
      width: '100%',
      maxWidth: '100% !important',
      height: '100%',
      maxHeight: '100%',
      borderRadius: 0
    }
  };

  return (
    <ModalBase {...restProps} {...modalProps} customStyle={customOverrides}>
      {children}
    </ModalBase>
  );
};

export default ModalFullScreen;
