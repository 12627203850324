import React from 'react';
import styled from 'styled-components';
import CircleNumber from 'components/styled/atoms/CircleNumber';
import breakpoint from '../breakpoints/breakpoints';
import { CSS_GUIDE } from '../constants';

const VoteStepper = ({ stepNbr, totalSteps, className }) => {
  return (
    <div className={className}>
      <CircleNumber>{stepNbr}</CircleNumber>
      <span className='vote-stepper__divider'>/</span>
      <span className='vote-stepper__total'>{totalSteps}</span>
    </div>
  );
};

const VoteStepperStyled = styled(VoteStepper).attrs({
  className: 'vote-stepper'
})`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  position: relative;
  
  .vote-stepper__total,
  .vote-stepper__divider {
    font-size: 1.5rem;
    color: #FFFFFF;
    font-weight: bold;
  }
  
  .vote-stepper__divider {
    margin-left: 5px;
  }
  
/*  .stepNbr {
    background-color: #FB6854;
    height: 38px;
    width: 38px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    justify-content: center;
    flex-wrap: nowrap;
    border-radius: 20px;
    font-size: 20px;
    line-height: 24px;
  }*/

  @media only screen and ${breakpoint.device.lg} {
    .vote-stepper__total,
    .vote-stepper__divider {
      color: ${CSS_GUIDE.colors.primary.hex};
    }

    &:before, &:after {
      content: '';
      display: block;
      position: absolute;
      width: 38%;
      height: 0.5px;
      background-color: ${CSS_GUIDE.colors.primary.hex};
    }

    &:before {
      //left: .5rem;
      left: 0;
    }

    &:after {
      right: 0;
    }
  }

`;

export default VoteStepperStyled;
VoteStepperStyled.displayName = 'VoteStepperStyled';
